import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { type DashboardDevice } from '../hooks/useDashboard'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { formatDistanceToNow } from 'date-fns'
import { cn } from '@/lib/utils'
import {
  errorStatuses,
  warningStatuses,
  mapGroupedStatusToBackgroundColorWithoutHover
} from '@/utils/statusHelpers'

interface DeviceTableProps {
  devices: (DashboardDevice)[]
}

export const DeviceTable = ({ devices }: DeviceTableProps) => {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])

  // Get background color class based on status using existing helpers
  const getStatusBgColor = (status: string) => {
    if (errorStatuses.includes(status as any)) {
      return mapGroupedStatusToBackgroundColorWithoutHover('error');
    }

    if (warningStatuses.includes(status as any)) {
      return mapGroupedStatusToBackgroundColorWithoutHover('warning');
    }

    return mapGroupedStatusToBackgroundColorWithoutHover('operating_well');
  }

  const columns = useMemo<ColumnDef<DashboardDevice>[]>(
    () => [
      {
        accessorKey: 'organizationName',
        header: 'Organization',
      },
      {
        accessorKey: 'name',
        header: 'Device Name',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ row }) => {
          const status = row.original.status
          return (
            <div className="font-medium">
              {status}
            </div>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: 'IMEI',
      },
      {
        accessorKey: 'activeSection',
        header: 'Active Section',
        cell: ({ row }) => row.original.activeSection || 'Unknown',
      },
      {
        accessorKey: 'autoRegulateState',
        header: 'Auto Regulate',
        cell: ({ row }) => row.original.autoRegulateState || 'Unknown',
      },
      {
        accessorKey: 'waterLevelPercentage',
        header: 'Water Level (L/R)',
        cell: ({ row }) => {
          const left = row.original.waterLevelLeft ? `${row.original.waterLevelLeft}mm` : '?';
          const right = row.original.waterLevelRight ? `${row.original.waterLevelRight}mm` : '?';
          return `${left} | ${right}`;
        },
      },
      {
        accessorKey: 'temperatureLeft',
        header: 'Temperature (L/R)',
        cell: ({ row }) => {
          const left = row.original.temperatureLeft
          const right = row.original.temperatureRight

          const leftStr = left !== null && left !== undefined ? `${left.toFixed(0)}°` : '?'
          const rightStr = right !== null && right !== undefined ? `${right.toFixed(0)}°` : '?'

          return `${leftStr} | ${rightStr}`
        },
      },
      {
        accessorKey: 'ecLeft',
        header: 'EC (L/R)',
        cell: ({ row }) => {
          const left = row.original.ecLeft
          const right = row.original.ecRight

          const leftStr = left !== null && left !== undefined ? `${left.toFixed(0)}` : '?'
          const rightStr = right !== null && right !== undefined ? `${right.toFixed(0)}` : '?'

          return `${leftStr} | ${rightStr}`
        },
      },
      {
        accessorKey: 'firmwareVersion',
        header: 'Firmware',
        cell: ({ row }) => row.original.firmwareVersion || 'Unknown',
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'lastDataTimestamp',
        header: 'Last Data',
        cell: ({ row }) => {
          const timestamp = row.original.lastDataTimestamp
          return timestamp
            ? formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true })
            : 'Never'
        },
      },
    ],
    []
  )

  const table = useReactTable({
    data: devices,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="whitespace-nowrap">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className={cn(
                  "cursor-pointer hover:brightness-95",
                  getStatusBgColor(row.original.status)
                )}
                onClick={() => navigate(`/admin/v3/org/${row.original.organizationId}/devices/${row.original.id}`)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No devices found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
} 
import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

// Define the metadata point type based on the backend model
export type MetadataPoint = {
    id: number;
    justBooted: boolean;
    resetReason: number;
    uptime: number;
    firmwareVersion?: string;
    modemFwVersion?: string;
    referenceSignal: number;
    errorStatus?: number;
    cycleTime?: number;
    deviceId: number;
    imei: string;
    createdAt: string;
};

// Define the response type based on our DTO in the backend
export type DeviceMetadataResponse = {
    metadataPoints: MetadataPoint[];
    totalCount: number;
    pageCount: number;
    currentPage: number;
};

const BASE_URL = '/admin/metadata';

// Function to fetch device metadata
const fetchDeviceMetadata = async ({
    deviceId,
    take,
    page,
    from,
    to,
    signal
}: {
    deviceId?: string;
    take?: number;
    page?: number;
    from?: Date;
    to?: Date;
    signal?: AbortSignal;
}): Promise<DeviceMetadataResponse> => {
    try {
        if (!deviceId) {
            throw new Error('Device ID is required');
        }

        // Build query parameters
        const params = new URLSearchParams();

        if (take) {
            params.append('take', take.toString());
        }

        if (page !== undefined) {
            params.append('page', page.toString());
        }

        if (from) {
            params.append('from', from.toISOString());
        }

        if (to) {
            params.append('to', to.toISOString());
        }

        // Make the API call
        const response = await axiosClient.get(`${BASE_URL}/device/${deviceId}/metadata`, {
            params,
            signal
        });

        return response.data;
    } catch (error) {
        // Log the error to Sentry
        Sentry.captureException(error);

        // Re-throw with better messaging
        if (error instanceof AxiosError) {
            throw new Error(`Failed to fetch device metadata: ${error.message}`);
        }

        throw error;
    }
};

// Custom hook that uses the fetch function
export const useDeviceMetadata = (
    deviceId?: string,
    take?: number,
    page?: number,
    from?: Date,
    to?: Date,
) => {
    return useQuery({
        queryKey: ['deviceMetadata', deviceId, take, page, from?.toISOString(), to?.toISOString()],
        queryFn: ({ signal }) => fetchDeviceMetadata({ deviceId, take, page, from, to, signal }),
        enabled: !!deviceId,
        refetchOnWindowFocus: false,
    });
}; 
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'

import type { OrgUserRole, UserRole } from '@/data/user'
import { ORG_USER_ROLE_ACCESS, USER_ROLE_ACCESS } from '@/utils/roleHelpers'

import { Toaster } from '../../ui/toaster'
import { useAuth } from '../AuthProvider'
import NoAccessErrorBoundary from '../errors/NoAccessErrorBoundary'
import HeaderBar from '../HeaderBar'
import { useOrg } from '../OrgProvider'

const AppLayout = ({
  userAccessRole,
  orgUserAccessRole,
  sidebar: Sidebar,
}: {
  userAccessRole?: UserRole
  orgUserAccessRole?: OrgUserRole
  sidebar?: React.ComponentType
}): JSX.Element | null => {
  const { user, isLoading } = useAuth()
  const location = useLocation()
  const { orgId } = useParams()
  const { selectedOrgId, setSelectedOrgId } = useOrg()

  if (!user && isLoading) return null
  if (!user) {
    return <Navigate to="/account/login" replace state={{ from: location }} />
  }

  if (orgId && orgId?.toString() !== selectedOrgId?.toString()) {
    setSelectedOrgId(Number(orgId))
  }

  if (
    userAccessRole &&
    !USER_ROLE_ACCESS.find((r) => r.key === userAccessRole)?.values.includes(
      user.role as UserRole,
    )
  ) {
    return <NoAccessErrorBoundary />
  }

  if (orgUserAccessRole) {
    const orgUser = user.organizationUsers.find(
      (ou) => ou.organizationId.toString() === orgId,
    )

    if (
      !orgUser ||
      !ORG_USER_ROLE_ACCESS.find(
        (r) => r.key === orgUserAccessRole,
      )?.values.includes(orgUser.role as OrgUserRole)
    ) {
      return <NoAccessErrorBoundary />
    }
  }

  return (
    <div className="flex min-h-screen flex-col">
      <HeaderBar />
      <main className="flex-1">
        {Sidebar ? (
          <div className="flex h-full">
            <Sidebar />
            <div className="flex-1 p-2 sm:px-4 md:px-8">
              <Outlet />
            </div>
          </div>
        ) : (
          <div className="p-2 sm:px-4 md:px-8">
            <Outlet />
          </div>
        )}
      </main>
      <Toaster />
    </div>
  )
}
export default AppLayout

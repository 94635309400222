import { useParams, useSearchParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDevice } from '../hooks/useDevice';
import { DeviceInfo } from '../components/info/DeviceInfo';
import { DeviceDataPoints } from '../components/data-points/DeviceDataPoints';
import { DevicePeripherals } from '../components/device-peripherals/DevicePeripherals';
import { RpcHistory } from '../components/rpcs/RpcHistory';
import { DeviceMetadata } from '../components/metadata/DeviceMetadata';
import { Button } from '@/components/ui/button'
import { ArrowLeftIcon } from 'lucide-react'
import { type Device } from '../hooks/useDevice'
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge'
import { DeviceStatus } from '@/data/device'
import { Link } from 'react-router-dom'

export const AdminDevicePage = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'info';

  const { data: device, isLoading, error } = useDevice(deviceId!);

  if (isLoading) {
    return <div>Loading device...</div>;
  }

  if (error || !device) {
    return <div>Error loading device</div>;
  }

  const handleTabChange = (value: string) => {
    setSearchParams({ tab: value });
  };

  return (
    <div className="space-y-4 p-8">
      <div className="flex flex-row justify-between">
        <Link to="/admin/v3/devices" className="flex items-center">
          <Button size="sm" variant="anonymous" className="hover:bg-whiteSmoke">
            <ArrowLeftIcon />
            <span className="hidden font-bold sm:block">Back To Devices</span>
          </Button>
        </Link>
        <div className="flex items-center gap-6">
          <h1 className="text-2xl font-semibold">{device.name}</h1>
          <div className="space-x-6 text-sm text-muted-foreground">
            <span className="font-mono">{device.imei}</span>
            <span>{device.organizationName}</span>
          </div>
          <div className="flex items-center gap-1.5">
            <DeviceStatusBadge status={device.status as DeviceStatus} />
          </div>
        </div>
      </div>

      <Tabs value={tab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="info">Info</TabsTrigger>
          <TabsTrigger value="data-points">Data Points</TabsTrigger>
          <TabsTrigger value="metadata">Metadata</TabsTrigger>
          <TabsTrigger value="rpcs">RPCs</TabsTrigger>
          <TabsTrigger value="components">Peripherals</TabsTrigger>
        </TabsList>

        <TabsContent value="info">
          <DeviceInfo device={device} />
        </TabsContent>
        <TabsContent value="data-points">
          <DeviceDataPoints deviceId={deviceId!} />
        </TabsContent>

        <TabsContent value="metadata">
          <DeviceMetadata deviceId={deviceId!} />
        </TabsContent>
        <TabsContent value="rpcs">
          <RpcHistory deviceId={deviceId!} />
        </TabsContent>
        <TabsContent value="components">
          <DevicePeripherals deviceId={deviceId!} />
        </TabsContent>
      </Tabs>
    </div>
  );
}; 
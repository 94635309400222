import clsx from "clsx"
import { NavLink } from "react-router-dom"

function AdminV3Sidebar() {
  return (
    <div className="w-64 border-r min-h-screen p-4">
      <nav>
        <NavLink 
          to="/admin/v3/devices"
          className={({ isActive }) =>
            clsx('block p-2', isActive && 'text-primary')
          }
        >
          Devices
        </NavLink>
        <NavLink 
          to="/admin/v3/peripherals"
          className={({ isActive }) =>
            clsx('block p-2', isActive && 'text-primary')
          }
        >
          Peripherals
        </NavLink>
      </nav>
    </div>
  )
}

export default AdminV3Sidebar
import { BASE_MATERIALS_DICT, BaseMaterial, calculationDict, getBaseMaterial } from '../data/baseMaterials';
import { CalculatedSpecification, LineItem, QuoteSpecifications, QuoteTotals, RawLineItem, RawSpecification } from '../types';


export const calculateArea = (width: number, length: number): number => {
    return width * length;
};

export const calculateWashboxesWidthwise = (specs: RawSpecification): number => {
    if (!specs.hasWashboxes || specs.washboxSpacing <= 0) {
        return 0;
    }
    return Math.floor(specs.width / specs.washboxSpacing);
};

export const calculateWashboxesLengthwise = (specs: RawSpecification): number => {
    if (!specs.hasWashboxes || specs.washboxSpacing <= 0) {
        return 0;
    }
    return Math.floor(specs.length / specs.washboxSpacing);
};

export const calculateTotalWashboxes = (specs: RawSpecification): number => {
    return calculateWashboxesWidthwise(specs) * calculateWashboxesLengthwise(specs);
};


export const calculateSpecificationValues = (rawSpecs: RawSpecification): CalculatedSpecification => {
    return {
        area: calculateArea(rawSpecs.width, rawSpecs.length),
        washboxesWidthwise: calculateWashboxesWidthwise(rawSpecs),
        washboxesLengthwise: calculateWashboxesLengthwise(rawSpecs),
        totalWashboxes: calculateTotalWashboxes(rawSpecs)
    };
};

/**
 * Calculate derived values for a line item
 */
export function calculateLineItemValues(rawItem: RawLineItem): LineItem {
    const cost = rawItem.units * rawItem.costPerUnit;
    const salesPerUnit = rawItem.costPerUnit * (1 + rawItem.markup / 100);
    const sales = rawItem.units * salesPerUnit;
    const profit = sales - cost;

    return {
        ...rawItem,
        cost,
        salesPerUnit,
        sales,
        profit
    };
}

export function calculateUnits(baseMaterial: BaseMaterial, specs: QuoteSpecifications): number {
    const calculation = baseMaterial.id in calculationDict ? calculationDict[baseMaterial.id as keyof typeof calculationDict] : null;
    let units = 0

    if (calculation) {
        units = calculation(specs);
    }

    return units;
}

/**
 * Recalculate quantities for existing line items based on new specifications
 */
export function recalculateMaterialQuantities(
    lineItems: LineItem[],
    specs: QuoteSpecifications
): LineItem[] {
    return lineItems.map(item => {
        // Try to find a base material with the same name
        const baseMaterial = BASE_MATERIALS_DICT[item.type];

        if (baseMaterial) {
            // Calculate new units
            // Calculate the number of units based on specifications
            let newUnits = calculateUnits(baseMaterial, specs);

            // Create updated raw item
            const rawItem: RawLineItem = {
                id: item.id,
                type: item.type,
                name: item.name,
                units: newUnits,
                unit: item.unit,
                costPerUnit: item.costPerUnit,
                markup: item.markup,
                comment: item.comment
            };

            // Calculate new derived values
            const calculatedValues = calculateLineItemValues(rawItem);

            // Return updated line item
            return {
                ...rawItem,
                ...calculatedValues
            };
        }

        // If no matching base material, return item unchanged
        return item;
    });
} 


export const calculateTotals = (materials: LineItem[], area: number): QuoteTotals => {
    const materialsCost = materials.reduce((sum, item) => sum + item.cost, 0);
    const materialsSales = materials.reduce((sum, item) => sum + item.sales, 0);
  
    const totalCost = materialsCost;
    const totalSales = materialsSales;
    const profit = totalSales - totalCost;
  
    return {
      totalCost,
      totalSales,
      costPerM2: area > 0 ? totalCost / area : 0,
      salePerM2: area > 0 ? totalSales / area : 0,
      margin: totalSales > 0 ? Math.round((profit / totalSales) * 100) : 0,
      profit
    };
  };
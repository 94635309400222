import { Quote } from '../types';

// Save all quotes to localStorage
export const saveQuotesToStorage = (quotes: Quote[]): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem('flowtrack_quotes', JSON.stringify(quotes));
            resolve();
        } catch (error) {
            console.error('Failed to save quotes to storage:', error);
            reject(error);
        }
    });
};

// Load all quotes from localStorage
export const loadQuotesFromStorage = (): Promise<Quote[]> => {
    return new Promise((resolve) => {
        try {
            const storedQuotes = localStorage.getItem('flowtrack_quotes');
            resolve(storedQuotes ? JSON.parse(storedQuotes) : []);
        } catch (error) {
            console.error('Failed to load quotes from storage:', error);
            resolve([]);
        }
    });
};

// Save a single quote (updates if exists, adds if not)
export const saveQuoteToStorage = async (quote: Quote): Promise<void> => {
    const quotes = await loadQuotesFromStorage();
    const index = quotes.findIndex(q => q.id === quote.id);

    if (index >= 0) {
        quotes[index] = {
            ...quote,
            updatedAt: new Date().toISOString()
        };
    } else {
        quotes.push({
            ...quote,
            updatedAt: new Date().toISOString()
        });
    }

    return saveQuotesToStorage(quotes);
};

// Get a single quote by ID
export const getQuoteFromStorage = async (id: string): Promise<Quote | null> => {
    const quotes = await loadQuotesFromStorage();
    return quotes.find(q => q.id === id) || null;
}; 
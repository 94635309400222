import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type OnChangeFn,
  type PaginationState,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { DataTablePagination } from '@/components/admin/DataTablePagination'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import type { Peripheral } from '../hooks/usePeripherals'
import { useColumns } from './columns'

interface PeripheralTableProps {
  data: Peripheral[]
  total: number
  isLoading: boolean
  pagination: PaginationState
  setPagination: OnChangeFn<PaginationState>
  onEdit: (id: number) => void
}

export function PeripheralTable({
  data,
  total,
  isLoading,
  pagination,
  setPagination,
  onEdit,
}: PeripheralTableProps) {
  const { t } = useTranslation(['admin'])
  const columns = useColumns(onEdit)

  const table = useReactTable({
    data,
    columns,
    pageCount: total ? Math.ceil(total / pagination.pageSize) : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="whitespace-nowrap">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: pagination.pageSize }).map((_, i) => (
                <TableRow className="odd:bg-sky-50 hover:bg-sky-100" key={i}>
                  {table.getAllColumns().map((_, k) => (
                    <TableCell key={k}>
                      <Skeleton className="h-2 w-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="odd:bg-sky-50 hover:bg-sky-100"
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-24 text-center"
                >
                  {t('data_table.no_results.text')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
} 
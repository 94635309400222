import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus, Settings } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';
import { getQuotes, initiateQuote } from './api/mockQuotesApi';
import { Quote } from './types';

export default function QuotesListPage() {
    const [quotes, setQuotes] = useState<Quote[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                setLoading(true);
                const fetchedQuotes = await getQuotes();
                setQuotes(fetchedQuotes);
            } catch (err) {
                setError(err instanceof Error ? err : new Error('Failed to fetch quotes'));
            } finally {
                setLoading(false);
            }
        };

        fetchQuotes();
    }, []);

    const handleCreateNewQuote = async () => {
        try {
            setLoading(true);
            const newQuote = await initiateQuote();
            navigate(`/sales/quotes/${newQuote.id}`);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to create new quote'));
            setLoading(false);
        }
    };

    const handleQuoteClick = (quoteId: string) => {
        navigate(`/sales/quotes/${quoteId}`);
    };

    const handleOpenMaterialSettings = () => {
        navigate('/sales/material-settings');
    };

    // Loading state
    if (loading && quotes.length === 0) {
        return (
            <div className="container mx-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Quotes</h1>
                    <Skeleton className="h-10 w-32" />
                </div>
                <Card>
                    <CardHeader>
                        <Skeleton className="h-8 w-40" />
                    </CardHeader>
                    <CardContent>
                        <Skeleton className="h-72 w-full" />
                    </CardContent>
                </Card>
            </div>
        );
    }

    // Error state
    if (error) {
        return (
            <div className="container mx-auto p-6">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Quotes</h1>
                    <Button onClick={handleCreateNewQuote}>
                        <Plus className="h-4 w-4 mr-2" />
                        New Quote
                    </Button>
                </div>
                <Card className="bg-red-50">
                    <CardHeader>
                        <CardTitle className="text-red-600">Error Loading Quotes</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-red-600">{error.message}</p>
                        <Button onClick={() => window.location.reload()} className="mt-4">
                            Retry
                        </Button>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Quotes</h1>
                <div className="flex gap-2">
                    <Button variant="outline" onClick={handleOpenMaterialSettings}>
                        <Settings className="h-4 w-4 mr-2" />
                        Material Settings
                    </Button>
                    <Button onClick={handleCreateNewQuote} disabled={loading}>
                        <Plus className="h-4 w-4 mr-2" />
                        New Quote
                    </Button>
                </div>
            </div>

            <Card>
                <CardHeader>
                    <CardTitle>All Quotes</CardTitle>
                </CardHeader>
                <CardContent>
                    {quotes.length === 0 ? (
                        <div className="text-center py-8">
                            <p className="text-gray-500 mb-4">No quotes found</p>
                            <Button onClick={handleCreateNewQuote}>
                                <Plus className="h-4 w-4 mr-2" />
                                Create Your First Quote
                            </Button>
                        </div>
                    ) : (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Dimensions</TableHead>
                                    <TableHead>Created</TableHead>
                                    <TableHead>Last Updated</TableHead>
                                    <TableHead>Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {quotes.map((quote) => (
                                    <TableRow 
                                        key={quote.id} 
                                        className="cursor-pointer hover:bg-gray-50"
                                        onClick={() => handleQuoteClick(quote.id)}
                                    >
                                        <TableCell className="font-medium">
                                            {quote.name || 'Untitled Quote'}
                                        </TableCell>
                                        <TableCell>
                                            {quote.specifications.width}m × {quote.specifications.length}m
                                        </TableCell>
                                        <TableCell>
                                            {new Date(quote.createdAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(quote.updatedAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <span className="capitalize">{quote.status}</span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>
        </div>
    );
} 
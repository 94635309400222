import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { useState, useEffect } from 'react'
import { RawSpecification, QuoteSpecifications } from '../types'
import { useDebouncedCallback } from 'use-debounce'

interface DimensionsTabProps {
    specifications: QuoteSpecifications;
    onSaveChanges: (specifications: RawSpecification) => void;
    onFeatureToggle: (feature: keyof Pick<RawSpecification, 'hasDriplines' | 'hasWashboxes' | 'hasGeothermal'>) => void;
}

export function DimensionsTab({
    specifications,
    onSaveChanges,
    onFeatureToggle
}: DimensionsTabProps) {
    // Local state for immediate updates
    const [localSpecs, setLocalSpecs] = useState<RawSpecification>(specifications);

    // Update local state when props change
    useEffect(() => {
        setLocalSpecs(specifications);
    }, [specifications]);

    // Debounced save callback
    const debouncedSave = useDebouncedCallback(
        (newSpecs: RawSpecification) => {
            onSaveChanges(newSpecs);
        },
        500
    );

    // Handle dimension changes
    const handleDimensionChange = (key: keyof Omit<RawSpecification, 'hasDriplines' | 'hasWashboxes' | 'hasGeothermal'>, value: number) => {
        const newSpecs = {
            ...localSpecs,
            [key]: value,
        };
        setLocalSpecs(newSpecs);
        debouncedSave(newSpecs);
    };

    // Handle feature toggles
    const handleFeatureToggle = (feature: keyof Pick<RawSpecification, 'hasDriplines' | 'hasWashboxes' | 'hasGeothermal'>) => {
        const newSpecs = {
            ...localSpecs,
            [feature]: !localSpecs[feature]
        };
        setLocalSpecs(newSpecs);
        debouncedSave(newSpecs);
        onFeatureToggle(feature);
    };

    // Render an editable dimension field
    const renderEditableDimension = (
        key: keyof Omit<RawSpecification, 'hasDriplines' | 'hasWashboxes' | 'hasGeothermal'>,
        value: number
    ) => {
        return (
            <div className="p-3 border rounded">
                <span className="text-xs font-medium text-slate-500 block capitalize">
                    {key.replace(/([A-Z])/g, ' $1').trim()}
                </span>
                <Input
                    type="number"
                    value={value || ''}
                    onChange={(e) => {
                        const val = e.target.value;
                        handleDimensionChange(key, val === '' ? 0 : parseFloat(val) || 0);
                    }}
                    className="mt-1"
                />
            </div>
        );
    };

    // Render a calculated (read-only) dimension field
    const renderCalculatedDimension = (
        label: string,
        value: number
    ) => {
        return (
            <div className="p-3 border rounded bg-slate-50">
                <span className="text-xs font-medium text-slate-500 block capitalize">
                    {label} <span className="text-xs text-slate-400">(calculated)</span>
                </span>
                <span className="text-lg font-semibold">{value}</span>
            </div>
        );
    };

    // Render feature toggle
    const renderFeature = (
        key: keyof Pick<RawSpecification, 'hasDriplines' | 'hasWashboxes' | 'hasGeothermal'>,
        value: boolean
    ) => {
        return (
            <div key={key} className="p-3 border rounded">
                <span className="text-xs font-medium text-slate-500 block capitalize">
                    {key.replace(/has/, '').replace(/([A-Z])/g, ' $1').trim()}
                </span>
                <Switch
                    checked={value}
                    onCheckedChange={() => handleFeatureToggle(key)}
                />
            </div>
        );
    };

    return (
        <>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {/* Editable dimensions (raw inputs) */}
                {renderEditableDimension('width', localSpecs.width)}
                {renderEditableDimension('length', localSpecs.length)}
                {renderEditableDimension('areaPerBasin', localSpecs.areaPerBasin)}

                {/* More editable dimensions */}
                {renderEditableDimension('gravelDepth', localSpecs.gravelDepth)}
                {renderEditableDimension('sandDepth', localSpecs.sandDepth)}
                {renderEditableDimension('sandTopLayer', localSpecs.sandTopLayer)}
                {renderEditableDimension('washboxSpacing', localSpecs.washboxSpacing)}
            </div>

            <div className="grid grid-cols-3 gap-4 mt-4">
                {/* Feature toggles */}
                {renderFeature('hasDriplines', localSpecs.hasDriplines)}
                {renderFeature('hasWashboxes', localSpecs.hasWashboxes)}
                {renderFeature('hasGeothermal', localSpecs.hasGeothermal)}
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {renderCalculatedDimension('Area', specifications.area)}
                {/* Additional calculated fields */}
                {specifications.hasWashboxes && (
                    <>
                        {renderCalculatedDimension('Washboxes Widthwise', specifications.washboxesWidthwise)}
                        {renderCalculatedDimension('Washboxes Lengthwise', specifications.washboxesLengthwise)}
                        {renderCalculatedDimension('Total Washboxes', specifications.totalWashboxes)}
                    </>
                )}
            </div>
        </>
    );
} 
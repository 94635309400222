import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Trash } from 'lucide-react'
import { LineItem } from '../types';
import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { calculateLineItemValues } from '../services/materialCalculator';

interface LineItemRowProps {
    item: LineItem;
    onItemChange?: (id: string, item: LineItem) => void;
    onItemRemove?: (id: string) => void;
    showComments: boolean;
}

export default function LineItemRow({
    item,
    onItemChange,
    onItemRemove,
    showComments
}: LineItemRowProps) {
    // Local state for immediate updates
    const [localItem, setLocalItem] = useState<LineItem>(item);

    // Update local state when items prop changes
    useEffect(() => {
        setLocalItem(item);
    }, [item]);

    // Debounced callback to parent
    const debouncedOnItemChange = useDebouncedCallback(
        (calculatedItem: LineItem) => {
            onItemChange?.(calculatedItem.id, calculatedItem);
        },
        500
    );

    // Handle immediate item change
    const handleItemChange = (field: keyof LineItem, value: any) => {
        const newItem = { ...localItem, [field]: value };
        const calculatedItem = calculateLineItemValues(newItem);
        setLocalItem(calculatedItem);

        // Trigger debounced update to parent
        debouncedOnItemChange(calculatedItem);
    }

    return (
        <tr key={localItem.id} className="hover:bg-slate-50">
            {onItemRemove && (
                <td className="border px-2 py-1">
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onItemRemove(localItem.id)}
                        className="h-5 w-5 text-red-500"
                    >
                        <Trash className="h-3 w-3" />
                    </Button>
                </td>
            )}
            <td className="border px-2 py-1">
                <Input
                    value={localItem.name}
                    onChange={(e) => handleItemChange('name', e.target.value)}
                    className="w-full h-7 px-1"
                />
            </td>
            <td className="border px-2 py-1 text-right">
                <Input
                    type="number"
                    value={localItem.units}
                    onChange={(e) => handleItemChange('units', parseFloat(e.target.value))}
                    className="w-16 h-7 px-1 ml-auto text-right"
                />
            </td>
            <td className="border px-2 py-1">
                {localItem.unit}
            </td>
            <td className="border px-2 py-1 text-right">
                <Input
                    type="number"
                    step="0.01"
                    value={localItem.costPerUnit}
                    onChange={(e) => handleItemChange('costPerUnit', parseFloat(e.target.value))}
                    className="w-20 h-7 px-1 ml-auto text-right"
                />
            </td>
            <td className="border px-2 py-1 text-right">{localItem.cost.toFixed(2)}</td>
            <td className="border px-2 py-1 text-right">
                <Input
                    type="number"
                    value={localItem.markup}
                    onChange={(e) => handleItemChange('markup', parseFloat(e.target.value))}
                    className="w-16 h-7 px-1 ml-auto text-right"
                />
            </td>
            <td className="border px-2 py-1 text-right">{localItem.salesPerUnit.toFixed(2)}</td>
            <td className="border px-2 py-1 text-right">{localItem.sales.toFixed(2)}</td>
            <td className="border px-2 py-1 text-right">{localItem.profit.toFixed(2)}</td>
            {showComments && (
                <td className="border px-2 py-1">
                    <Input
                        value={localItem.comment || ''}
                        onChange={(e) => handleItemChange('comment', e.target.value)}
                        className="w-full h-7 px-1"
                    />
                </td>
            )}
        </tr>
    )
} 
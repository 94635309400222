import type { RouteObject } from 'react-router-dom'
import QuotesPage from './features/quotes/quotes-page'
import QuotesListPage from './features/quotes/quotes-list-page'
import MaterialSettingsPage from './features/quotes/material-settings-page'

export const salesRoutes: RouteObject[] = [
    {
        children: [
            {
                path: 'quotes',
                element: <QuotesListPage />
            },
            {
                path: 'quotes/:id',
                element: <QuotesPage />
            },
            {
                path: 'material-settings',
                element: <MaterialSettingsPage />
            },
        ]
    }
] 
import { LineItemTable } from './LineItemTable';
import { LineItem, RawLineItem } from '../types';

interface MaterialsTabProps {
    materials: LineItem[];
    onSaveChanges: (materials: RawLineItem[]) => void;
    onMaterialRemove?: (id: string) => void;
}

export function MaterialsTab({
    materials,
    onSaveChanges,
    onMaterialRemove
}: MaterialsTabProps) {
    // Handle item change
    const handleItemChange = (id: string, newItem: LineItem) => {
        // Create updated materials array with the changed item
        const updatedMaterials = materials.map(item =>
            item.id === id
                ? newItem
                : item
        );

        onSaveChanges(updatedMaterials);
    };

    return (
        <div>
            <LineItemTable
                items={materials}
                onItemChange={handleItemChange}
                onItemRemove={onMaterialRemove}
                showComments={true}
            />
        </div>
    );
} 
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link, MoreVertical, Trash } from 'lucide-react';
import { type DevicePeripheral } from '../../hooks/useDevicePeripherals';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

interface PeripheralCardProps {
  peripheral: DevicePeripheral;
  onEdit: (peripheral: DevicePeripheral) => void;
  onDelete: (peripheral: DevicePeripheral) => void;
}

export const PeripheralCard = ({ peripheral, onEdit, onDelete }: PeripheralCardProps) => {
  return (
    <Card className="p-3 hover:shadow-md transition-shadow duration-200">
      {/* Header */}
      <div className="flex justify-between items-start">
        <h3 className="text-base font-medium text-slate-900">{peripheral.name}</h3>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">Open menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onEdit(peripheral)}>
              Edit peripheral
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => onDelete(peripheral)}
              className="text-destructive"
            >
              Delete peripheral
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {/* Tech Specs - only show if exists */}
      {peripheral?.peripheral && (
        <div className="mt-2">
          <p className="text-xs text-slate-600">
            {peripheral.peripheral.name}, {peripheral.peripheral.manufacturer}
          </p>
        </div>
      )}

      {/* Info Grid */}
      <div className="mt-2 grid grid-cols-2 gap-2 text-xs">
        <div>
          <span className="text-slate-500">Address</span>
          <p className="font-mono">{peripheral.address}</p>
        </div>
        <div>
          <span className="text-slate-500">Section</span>
          <p className="capitalize">{peripheral.section}</p>
        </div>
      </div>

      {/* Action */}
      {/* <div className="mt-2 pt-2 border-t flex justify-between items-center">
        <span className="text-xs text-slate-500">Mapping</span>
        <Button variant="ghost" size="sm" className="h-7">
          <Link className="h-3 w-3 mr-1" />
          Map
        </Button>
      </div> */}
    </Card>
  );
}; 
import { QuoteSpecifications } from "../types";

/**
 * Material coefficients for calculations
 */
export const MATERIAL_COEFFICIENTS = {
    SAND_DENSITY: 1.6, // kg/m³
    GRAVEL_DENSITY: 1.6, // kg/m³
    TOP_LAYER_SAND_DENSITY: 1.6, // kg/m³
    ADMIX_M3_PER_CANNISTER: 7, // m³/cannister
};


export const calculationDict = {
    'cap-material': (specs: QuoteSpecifications) => Math.round(specs.area * 0.05),
    'contractor-fee': (specs: QuoteSpecifications) => specs.area,
    'drain-pipe-100mm': (specs: QuoteSpecifications) => Math.round(specs.width + ((specs.area) / 5)),
    'dripline': (specs: QuoteSpecifications) => specs.hasDriplines ? Math.round(specs.area / 2) : 0,
    'dripline-connectors': (specs: QuoteSpecifications) => specs.hasDriplines ? Math.round(specs.length) : 0,
    'dripline-feeder': (specs: QuoteSpecifications) => specs.hasDriplines ? Math.round(2 * specs.width + 2 * specs.length) : 0,
    'geothermal-pipe': (specs: QuoteSpecifications) => specs.hasGeothermal ? Math.round(specs.area) : 0,
    'hydroponics-control': (specs: QuoteSpecifications) => Math.ceil(specs.area / specs.areaPerBasin),
    'liner': (specs: QuoteSpecifications) => Math.round((specs.width + 2) * (specs.length + 2)),
    'solid-pipes-100mm-basin': (specs: QuoteSpecifications) => Math.ceil(specs.area / specs.areaPerBasin) * 10,
    'solid-pipes-100mm-washbox': (specs: QuoteSpecifications) => {
        if (!specs.hasWashboxes) return 0;
        const minDimension = Math.min(specs.width, specs.length);
        const maxDimension = Math.max(specs.width, specs.length);
        const washboxRows = Math.round(maxDimension / specs.washboxSpacing);
        return Math.round((minDimension - specs.washboxSpacing / 2) * washboxRows);
    },
    'washbox': (specs: QuoteSpecifications) => specs.hasWashboxes ? specs.totalWashboxes : 0,
    'solenoid-valves': (specs: QuoteSpecifications) => {
        const basins = Math.ceil(specs.area / specs.areaPerBasin);
        return specs.hasDriplines ? basins * 2 : basins;
    },
    'sand': (specs: QuoteSpecifications) => Math.round((specs.area * specs.sandDepth * MATERIAL_COEFFICIENTS.SAND_DENSITY / 1000) * 1.1),
    'gravel': (specs: QuoteSpecifications) => Math.round((specs.area * specs.gravelDepth * MATERIAL_COEFFICIENTS.GRAVEL_DENSITY / 1000) * 1.25),
    'marine-plywood': (specs: QuoteSpecifications) => Math.min(specs.width, specs.length),
    'non-geothermal-basin': (specs: QuoteSpecifications) => !specs.hasGeothermal ? Math.ceil(specs.area / specs.areaPerBasin) : 0,
    'concrete-mix': (specs: QuoteSpecifications) => Math.round(specs.area * 0.05),
    'travel-supervision': () => 1,
    'shipping-basins': (specs: QuoteSpecifications) => Math.ceil(specs.area / specs.areaPerBasin),
    'control-unit': () => 1,
    'shipping-liner': () => 1,
    'welding-liner': () => 1,
}
export interface BaseMaterial {
    id: string;
    type: keyof typeof calculationDict;
    name: string;
    unit: string;
    costPerUnit: number;
    markup: number;
    description?: string;
}

/**
 * Collection of base materials with their properties and calculation rules
 */
export const BASE_MATERIALS: BaseMaterial[] = [
    {
        id: 'cap-material',
        type: 'cap-material',
        name: 'Capillary Material Admix',
        unit: 'm³',
        costPerUnit: 12.00,
        markup: 1070,
        description: 'High performance capillary material',
    },
    {
        id: 'non-geothermal-basin',
        type: 'non-geothermal-basin',
        name: 'Capillary Non-geothermal Basin',
        unit: 'Pcs',
        costPerUnit: 2000.00,
        markup: 40,
    },
    {
        id: 'concrete-mix',
        type: 'concrete-mix',
        name: 'Concrete mix',
        unit: 'm³',
        costPerUnit: 0.00,
        markup: 30,
    },
    {
        id: 'contractor-fee',
        type: 'contractor-fee',
        name: ' Contractor',
        unit: 'm²',
        costPerUnit: 0,
        markup: 0,
    },
    {
        id: 'drain-pipe-100mm',
        type: 'drain-pipe-100mm',
        name: 'Drain Pipe 100mm',
        unit: 'm',
        costPerUnit: 0,
        markup: 0,
    },
    {
        id: 'dripline',
        type: 'dripline',
        name: 'Dripline',
        unit: 'm',
        costPerUnit: 2.70,
        markup: 30,
    },
    {
        id: 'dripline-connectors',
        type: 'dripline-connectors',
        name: 'Dripline connectors',
        unit: 'Pcs',
        costPerUnit: 2.70,
        markup: 30,
    },
    {
        id: 'dripline-feeder',
        type: 'dripline-feeder',
        name: 'Dripline feeder pipe',
        unit: 'm',
        costPerUnit: 2.70,
        markup: 30,
    },
    {
        id: 'geothermal-pipe',
        type: 'geothermal-pipe',
        name: 'Geothermal pipe',
        unit: 'm',
        costPerUnit: 0.00,
        markup: 9,
    },
    {
        id: 'gravel',
        type: 'gravel',
        name: 'Gravel',
        unit: 'm³',
        costPerUnit: 0,
        markup: 0,
    },
    {
        id: 'hydroponics-control',
        type: 'hydroponics-control',
        name: 'Hydroponics control box',
        unit: 'Pcs',
        costPerUnit: 300.00,
        markup: 120,
    },
    {
        id: 'liner',
        type: 'liner',
        name: 'Liner',
        unit: 'm²',
        costPerUnit: 3.50,
        markup: 35,
    },
    {
        id: 'marine-plywood',
        type: 'marine-plywood',
        name: 'Marine Plywood',
        unit: 'm',
        costPerUnit: 0,
        markup: 0,
    },
    {
        id: 'sand',
        type: 'sand',
        name: 'Sand',
        unit: 'm³',
        costPerUnit: 0,
        markup: 0,
    },
    {
        id: 'solenoid-valves',
        type: 'solenoid-valves',
        name: 'Solenoid valves',
        unit: 'Pcs',
        costPerUnit: 20.00,
        markup: 30,
    },
    {
        id: 'solid-pipes-100mm-basin',
        type: 'solid-pipes-100mm-basin',
        name: 'Solid pipes 100 mm for basin',
        unit: 'm',
        costPerUnit: 20.00,
        markup: 30,
    },
    {
        id: 'solid-pipes-100mm-washbox',
        type: 'solid-pipes-100mm-washbox',
        name: 'Solid pipes 100 mm for washboxes',
        unit: 'm',
        costPerUnit: 20.00,
        markup: 30,
    },
    {
        id: 'washbox',
        type: 'washbox',
        name: 'Washbox',
        unit: 'Pcs',
        costPerUnit: 12.00,
        markup: 400,
    },
    {
        id: 'travel-supervision',
        type: 'travel-supervision',
        name: 'Travel/supervision',
        unit: 'Pcs',
        costPerUnit: 3000.00,
        markup: 10,
    },
    {
        id: 'shipping-basins',
        type: 'shipping-basins',
        name: 'Shipping basin(s)',
        unit: 'Pcs',
        costPerUnit: 500.00,
        markup: 10,
    },
    {
        id: 'control-unit',
        type: 'control-unit',
        name: 'Control unit',
        unit: 'Pcs',
        costPerUnit: 590.00,
        markup: 25,
    },
    {
        id: 'shipping-liner',
        type: 'shipping-liner',
        name: 'Shipping liner',
        unit: 'Pcs',
        costPerUnit: 500.00,
        markup: 10,
    },
    {
        id: 'welding-liner',
        type: 'welding-liner',
        name: 'Welding liner',
        unit: 'Pcs',
        costPerUnit: 0.00,
        markup: 25,
    },
];

export const BASE_MATERIALS_DICT = BASE_MATERIALS.reduce((acc, material) => {
    acc[material.id] = material;
    return acc;
}, {} as Record<string, BaseMaterial>);

export function getBaseMaterial(id: string): BaseMaterial | undefined {
    return BASE_MATERIALS_DICT[id];
}

/**
 * Get all base materials
 */
export function getAllBaseMaterials(): BaseMaterial[] {
    return [...BASE_MATERIALS];
} 
import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';

const BASE_URL = '/admin/data';

export type PeripheralValue = {
  value: number;
  timestamp: string;
  component_id: number;
};

export type SectionValues = {
  left?: PeripheralValue;
  right?: PeripheralValue;
  standalone?: PeripheralValue;
};

export type DeviceLatestData = {
  water_level?: SectionValues;
  temperature?: SectionValues;
  tank_temperature?: SectionValues;
  moisture?: SectionValues;
  ec?: SectionValues;
  fill_valve?: SectionValues;
  drain_valve?: SectionValues;
  drip_line_valve?: SectionValues;
  fertigation_valve?: SectionValues;
  balance_valve?: SectionValues;
};

const fetchDeviceLatestData = async ({ deviceId, signal }: { deviceId: string; signal?: AbortSignal }) => {
  try {
    const response = await axiosClient.get<DeviceLatestData>(`${BASE_URL}/device/${deviceId}/latest`, { signal });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const useDeviceLatestData = (deviceId: string) => {
  return useQuery({
    queryKey: ['device-latest-data', deviceId],
    queryFn: ({ signal }) => fetchDeviceLatestData({ deviceId, signal }),
  });
}; 
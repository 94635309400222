import type { RouteObject } from 'react-router-dom'
import { AdminDevicePage } from './features/device/pages/admin-device-page'
import { AdminDevicesDashboard } from './features/device/pages/admin-devices-dashboard'
import PeripheralsPage from './features/peripherals/peripherals-page'
export const adminV3Routes: RouteObject[] = [
    {
        children: [
            {
                path: 'devices',
                element: <AdminDevicesDashboard />
            },
            {
                path: 'org/:orgId/devices/:deviceId',
                element: <AdminDevicePage />
            },
            {
                path: 'peripherals',
                element: <PeripheralsPage />
            }
        ]
    }
] 
import { RefreshCw, Download, Edit, Check, X, ArrowLeft } from 'lucide-react'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Input } from '@/components/ui/input'
import { DimensionsTab } from './components/DimensionsTab'
import { MaterialsTab } from './components/MaterialsTab'
import { useQuote } from './hooks/useQuote'
import { Skeleton } from '@/components/ui/skeleton'
import { RawSpecification } from './types'
import SummaryCard from './components/SummaryCard'
import { SyncStatusIndicator } from './components/SyncStatusIndicator'

export default function QuotesPage() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('specs');
    const [isEditingName, setIsEditingName] = useState(false);
    const [tempQuoteName, setTempQuoteName] = useState('');

    // Use our quote hook to fetch data, passing the ID from URL
    const {
        quote,
        loading,
        error,
        updateSpecifications,
        updateMaterials,
        fetchQuote,
        updateName,
        syncStatus,
        lastSynced
    } = useQuote(id);

    // Handle back to list
    const handleBackToList = () => {
        navigate('/sales/quotes');
    };

    // Handle save changes for specifications
    const handleSaveSpecifications = (rawSpecs: RawSpecification) => {
        updateSpecifications(rawSpecs);
    };

    // Handle edit name click
    const handleEditNameClick = () => {
        setTempQuoteName(quote?.name || 'Capillary Flow Quote');
        setIsEditingName(true);
    };

    // Handle save name
    const handleSaveName = () => {
        if (quote) {
            updateName(tempQuoteName || 'Capillary Flow Quote');
        }
        setIsEditingName(false);
    };

    // Handle cancel edit
    const handleCancelEdit = () => {
        setIsEditingName(false);
    };

    // If still loading, show skeleton
    if (loading && !quote) {
        return (
            <div className="container mx-auto p-6">
                <div className="mb-4">
                    <Button variant="ghost" onClick={handleBackToList}>
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        Back to Quotes
                    </Button>
                </div>
                <div className="relative my-6 flex justify-between items-center">
                    <Skeleton className="h-10 w-64" />
                    <div className="flex gap-2">
                        <Skeleton className="h-10 w-32" />
                        <Skeleton className="h-10 w-32" />
                    </div>
                </div>
                <div className="flex gap-6">
                    <div className="flex-[3]">
                        <Skeleton className="h-96 w-full" />
                    </div>
                    <div className="flex-1">
                        <Skeleton className="h-96 w-full" />
                    </div>
                </div>
            </div>
        );
    }

    // If error, show error message
    if (error) {
        return (
            <div className="container mx-auto p-6">
                <div className="mb-4">
                    <Button variant="ghost" onClick={handleBackToList}>
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        Back to Quotes
                    </Button>
                </div>
                <div className="relative my-6">
                    <h2 className="text-3xl font-bold text-red-600">Error Loading Quote</h2>
                    <p className="mt-4">{error.message}</p>
                    <Button onClick={fetchQuote} className="mt-4">Retry</Button>
                </div>
            </div>
        );
    }

    // If no quote found
    if (!quote) {
        return (
            <div className="container mx-auto p-6">
                <div className="mb-4">
                    <Button variant="ghost" onClick={handleBackToList}>
                        <ArrowLeft className="h-4 w-4 mr-2" />
                        Back to Quotes
                    </Button>
                </div>
                <div className="relative my-6">
                    <h2 className="text-3xl font-bold">No Quote Available</h2>
                    <p className="mt-4">The quote you are looking for could not be found.</p>
                </div>
            </div>
        );
    }

    const handleMaterialRemove = (id: string) => {
        const updatedRawMaterials = quote.materials.filter(item => item.id !== id);
        updateMaterials(updatedRawMaterials);
    };

    return (
        <div className="container mx-auto p-6">
            <div className="mb-4">
                <Button variant="ghost" onClick={handleBackToList}>
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Back to Quotes
                </Button>
            </div>
            <div className="relative my-6 flex justify-between items-center">
                {isEditingName ? (
                    <div className="flex items-center gap-2">
                        <Input
                            value={tempQuoteName}
                            onChange={(e) => setTempQuoteName(e.target.value)}
                            className="text-2xl font-bold p-2 min-w-[400px]"
                            autoFocus
                        />
                        <Button size="sm" variant="ghost" onClick={handleSaveName}>
                            <Check className="h-5 w-5 text-green-600" />
                        </Button>
                        <Button size="sm" variant="ghost" onClick={handleCancelEdit}>
                            <X className="h-5 w-5 text-red-600" />
                        </Button>
                    </div>
                ) : (
                    <div className="flex items-center gap-2">
                        <h2 className="text-3xl font-bold">{quote.name || 'Capillary Flow Quote'}</h2>
                        <Button size="sm" variant="ghost" onClick={handleEditNameClick}>
                            <Edit className="h-4 w-4 text-gray-500" />
                        </Button>
                    </div>
                )}
                <div className="flex items-center gap-4">
                    <SyncStatusIndicator status={syncStatus} lastSynced={lastSynced} />
                    <Button>
                        <Download className="h-4 w-4 mr-2" />
                        Export
                    </Button>
                </div>
            </div>

            <div className="flex gap-6">
                {/* Main content - 75% */}
                <div className="flex-[4]">
                    <Card className="mb-6">
                        <CardHeader className="pb-3">
                            <CardTitle className="text-xl font-bold">
                                Project Specifications: {quote.specifications.width}x{quote.specifications.length}m
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Tabs defaultValue="specs" value={activeTab} onValueChange={setActiveTab}>
                                <TabsList className="mb-4">
                                    <TabsTrigger value="specs">Dimensions</TabsTrigger>
                                    <TabsTrigger value="materials">Materials</TabsTrigger>
                                </TabsList>

                                <TabsContent value="specs">
                                    <DimensionsTab
                                        specifications={quote.specifications}
                                        onSaveChanges={handleSaveSpecifications}
                                        onFeatureToggle={() => { }} // This is handled in handleSaveSpecifications now
                                    />
                                </TabsContent>

                                <TabsContent value="materials">
                                    <MaterialsTab
                                        materials={quote.materials}
                                        onSaveChanges={updateMaterials}
                                    // onMaterialRemove={handleMaterialRemove}
                                    />
                                </TabsContent>
                            </Tabs>
                        </CardContent>
                    </Card>
                </div>

                <SummaryCard quote={quote} />
            </div>
        </div>
    );
} 
import { useState, useEffect, useCallback, useRef } from 'react';
import { getQuote, updateSpecifications, updateMaterials, updateQuoteName } from '../api/mockQuotesApi';
import { Quote, RawSpecification, RawLineItem } from '../types';
import { BASE_MATERIALS } from '../data/baseMaterials';
import { generateMaterialLineItem } from '../services/materialGenerator';
import { saveQuoteToStorage } from '../services/storageService';

export function useQuote(quoteId?: string) {
    const [quote, setQuote] = useState<Quote | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    // New sync state
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [syncStatus, setSyncStatus] = useState<'idle' | 'syncing' | 'success' | 'error'>('idle');
    const [lastSynced, setLastSynced] = useState<string | null>(null);

    // Reference to track the current quote for the interval
    const quoteRef = useRef<Quote | null>(null);

    // Update ref when quote changes
    useEffect(() => {
        quoteRef.current = quote;
    }, [quote]);

    // Fetch quote data
    const fetchQuote = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);

            const fetchedQuote = await getQuote(quoteId);
            setQuote(fetchedQuote);

            // Set lastSynced to the quote's updatedAt time
            setLastSynced(fetchedQuote.updatedAt);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An unknown error occurred'));
        } finally {
            setLoading(false);
        }
    }, [quoteId]);

    // Load quote on initial render
    useEffect(() => {
        fetchQuote();
    }, [fetchQuote]);

    // Set up sync interval
    useEffect(() => {
        // Function to sync quote to storage
        const syncQuote = async () => {
            if (quoteRef.current && isDirty) {
                try {
                    setSyncStatus('syncing');
                    await saveQuoteToStorage(quoteRef.current);
                    setSyncStatus('success');
                    setLastSynced(new Date().toISOString());
                    setIsDirty(false);

                    // Reset to idle after showing success
                    setTimeout(() => setSyncStatus('idle'), 2000);
                } catch (err) {
                    setSyncStatus('error');
                    console.error('Failed to sync quote:', err);
                }
            }
        };

        // Set up interval
        const intervalId = setInterval(syncQuote, 5000);

        // Clean up - sync on unmount if dirty
        return () => {
            clearInterval(intervalId);
            if (quoteRef.current && isDirty) {
                // Immediate sync when leaving
                syncQuote();
            }
        };
    }, [isDirty]);

    // Update specifications
    const updateQuoteSpecifications = useCallback(async (specs: Partial<RawSpecification>) => {
        if (!quote) return;

        try {
            setLoading(true);

            const updatedQuote = await updateSpecifications(quote.id, specs);
            setQuote(updatedQuote);
            setIsDirty(true); // Mark as dirty after update
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to update specifications'));
        } finally {
            setLoading(false);
        }
    }, [quote]);

    // Update materials - now accepts RawLineItem[] to match the API
    const updateQuoteMaterials = useCallback(async (materials: RawLineItem[]) => {
        if (!quote) return;

        try {
            setLoading(true);
            const updatedQuote = await updateMaterials(quote.id, materials);
            setQuote(updatedQuote);
            setIsDirty(true); // Mark as dirty after update
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to update materials'));
        } finally {
            setLoading(false);
        }
    }, [quote]);

    // Add a new material from base materials
    const addMaterialFromBase = useCallback(async (baseMaterialId: string) => {
        if (!quote) return;

        try {
            setLoading(true);
            // Generate line item from base material
            const newLineItem = generateMaterialLineItem(baseMaterialId, quote.specifications);

            if (!newLineItem) {
                throw new Error(`Base material with ID ${baseMaterialId} not found`);
            }

            // Add to existing materials
            const updatedMaterials = [...quote.materials, newLineItem];

            // Update quote materials
            const updatedQuote = await updateMaterials(
                quote.id,
                updatedMaterials
            );

            setQuote(updatedQuote);
            setIsDirty(true); // Mark as dirty after update
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to add material'));
        } finally {
            setLoading(false);
        }
    }, [quote, updateMaterials]);

    // Get available base materials
    const getAvailableBaseMaterials = useCallback(() => {
        if (!quote) return [];

        // Filter out materials that are already in the quote
        return BASE_MATERIALS.filter(baseMaterial =>
            !quote.materials.some(material => material.name === baseMaterial.name)
        );
    }, [quote]);

    // Update quote name
    const updateName = useCallback(async (name: string) => {
        if (!quote) return;

        try {
            setLoading(true);
            const updatedQuote = await updateQuoteName(quote.id, name);
            setQuote(updatedQuote);
            setIsDirty(true); // Mark as dirty after update
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to update quote name'));
        } finally {
            setLoading(false);
        }
    }, [quote]);

    // Manually trigger sync - useful for implementing a save button if needed
    const manualSync = useCallback(async () => {
        if (!quote) return;

        try {
            setSyncStatus('syncing');
            await saveQuoteToStorage(quote);
            setSyncStatus('success');
            setLastSynced(new Date().toISOString());
            setIsDirty(false);

            // Reset to idle after showing success
            setTimeout(() => setSyncStatus('idle'), 2000);
        } catch (err) {
            setSyncStatus('error');
            console.error('Failed to sync quote manually:', err);
        }
    }, [quote]);

    return {
        quote,
        loading,
        error,
        syncStatus,
        lastSynced,
        fetchQuote,
        updateSpecifications: updateQuoteSpecifications,
        updateMaterials: updateQuoteMaterials,
        addMaterialFromBase,
        getAvailableBaseMaterials,
        updateName,
        manualSync
    };
} 
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Pencil, Plus } from "lucide-react";
import { type PeripheralConfig } from "./device-mapping-types";
import { type PeripheralValue } from "../../hooks/useDeviceLatestData";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "@/components/ui/badge";
import { Section } from "@/admin-v3/models/types";

interface ActuatorCardProps {
  title: string;
  icon: React.ReactNode;
  position: Section;
  leftPeripheral?: PeripheralConfig;
  rightPeripheral?: PeripheralConfig;
  leftValue?: PeripheralValue;
  rightValue?: PeripheralValue;
  peripheralFunction: string;
  onMapClick: (section: Section) => void;
  onEditClick: (section: Section) => void;
}

const formatActuatorValue = (value: number) => {
  return value >= 1 ? 'Active' : 'Inactive';
};

const ActuatorInfo = ({
  config,
  value,
  onMap,
  onEdit
}: {
  config?: PeripheralConfig;
  value?: PeripheralValue;
  onMap: () => void;
  onEdit: () => void;
}) => {
  if (!config?.devicePeripheral) {
    return (
      <div className="flex items-center justify-between py-1">
        <span className="text-xs text-slate-400 font-light">Configure</span>
        <Button
          variant="ghost"
          size="sm"
          onClick={onMap}
          className="text-slate-500 hover:text-slate-800 h-6 px-2"
        >
          <Plus className="h-3 w-3 mr-1" />
          Add
        </Button>
      </div>
    );
  }

  return (
    <div className="group py-1">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1.5">
          <span className="text-sm font-medium text-slate-700">{config.devicePeripheral.name}</span>
          <Badge className="text-xs py-0 px-1.5 h-5" variant={value?.value === 1 ? "default" : "secondary"}>
            {formatActuatorValue(value?.value ?? 0)}
          </Badge>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={onEdit}
          className="text-slate-400 hover:text-slate-800 h-5 px-1"
        >
          <Pencil className="h-2.5 w-2.5" />
        </Button>
      </div>
      <div className="text-xs text-slate-500 mt-0.5">
        <span>{config.devicePeripheral.address} @ {config.devicePeripheralIndex}</span>
        {value && (
          <span className="ml-1">• {formatDistanceToNow(new Date(value.timestamp), { addSuffix: true })}</span>
        )}
      </div>
    </div>
  );
};

export const ActuatorCard = ({
  title,
  icon,
  position,
  leftPeripheral,
  leftValue,
  peripheralFunction,
  onMapClick,
  onEditClick,
}: ActuatorCardProps) => {
  return (
    <Card className="p-2 hover:shadow-md transition-shadow duration-200">
      <CardHeader className="p-0 pb-1 px-0 flex flex-row items-center justify-center space-y-0">
        <CardTitle className="text-sm text-slate-500 font-normal flex items-center gap-1">
          {icon}
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="px-0 pt-1">
        <ActuatorInfo
          config={leftPeripheral}
          value={leftValue}
          onMap={() => onMapClick('standalone')}
          onEdit={() => onEditClick('standalone')}
        />
      </CardContent>
    </Card>
  );
}; 
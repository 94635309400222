import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { type DeviceRpcDTO } from '../../hooks/useDeviceRpcs';

interface RpcListProps {
  rpcs: DeviceRpcDTO[];
}

export const RpcList = ({ rpcs }: RpcListProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Type</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Created</TableHead>
          <TableHead>Downlinked</TableHead>
          <TableHead>Executed</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {rpcs.map((command) => (
          <TableRow key={command.id}>
            <TableCell>{command.type}</TableCell>
            <TableCell>
              <span className={`inline-block px-2 py-1 rounded-full text-xs font-medium ${
                command.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                command.status === 'downlinked' ? 'bg-blue-100 text-blue-800' :
                command.status === 'executed' ? 'bg-green-100 text-green-800' :
                'bg-red-100 text-red-800'
              }`}>
                {command.status}
              </span>
            </TableCell>
            <TableCell>{new Date(command.createdAt).toLocaleString()}</TableCell>
            <TableCell>{command.downlinkedAt ? new Date(command.downlinkedAt).toLocaleString() : '-'}</TableCell>
            <TableCell>{command.executedAt ? new Date(command.executedAt).toLocaleString() : '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}; 
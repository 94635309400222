import { Card, CardContent } from '@/components/ui/card';
import { type Device } from '../../hooks/useDevice';
import DeviceDataChart from '@/components/v2/DeviceDataChart';
import useDeviceData from '@/hooks/useDeviceData';
import { addDays } from 'date-fns';
import useDevice from '@/hooks/useDevice';
import DeviceIllustrationCard from '@/components/v2/DeviceIllustrationCard';
import DeviceTimeline from '../timeline/Timeline';

const DEFAULT_FROM_DATE = addDays(new Date(), -1)
const DEFAULT_TO_DATE = new Date(new Date().setHours(23, 59, 59, 999))

interface DeviceInfoProps {
  device: Device;
}

export const DeviceInfo = ({ device }: DeviceInfoProps) => {

  const {
    device: detailedDevice,
  } = useDevice(device.id.toString(), import.meta.env.VITE_DEVICES_POLLING_INTERVAL)
  const { deviceData: historicalData, isLoading: isLoadingHistorical } = useDeviceData(
    device.id.toString(),
    DEFAULT_FROM_DATE,
    DEFAULT_TO_DATE,
    true
  )

  if (!detailedDevice) {
    return <div>Loading...</div>
  }

  return (
    <div className="flex flex-col gap-6 w-full pt-6">
      <div className="flex flex-col xl:flex-row gap-6 w-full">
        {/* Configuration */}
        <div className="flex-1 lg:min-w-[580px] max-w-[580px]">
          {detailedDevice ? <DeviceIllustrationCard device={detailedDevice} /> : null}
        </div>
        <Card className="flex-1">
          <CardContent>
            {detailedDevice ? (
              <DeviceDataChart
                deviceData={historicalData}
                isLoading={isLoadingHistorical}
                showTimeOnly={true}
                showCondensed={true}
                materialHeightBottom={detailedDevice.materialHeight}
                materialHeightTop={
                  detailedDevice.materialHeight + (detailedDevice.materialDepth || 0)
                }
                distanceUnitSuffix="mm"
                tempUnitSuffix="°C"
                leftSectionAlias={detailedDevice.leftSectionAlias}
                rightSectionAlias={detailedDevice.rightSectionAlias}
                defaultToDate={DEFAULT_TO_DATE}
                defaultFromDate={DEFAULT_FROM_DATE}
              />) : null}
          </CardContent>
        </Card>
        {/* Additional sections can be added here as needed */}
      </div>
      <DeviceTimeline imei={device.imei} />
    </div>
  );
}; 
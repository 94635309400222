import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

const BASE_URL = '/admin/data';

export type DataPoint = {
    id: number;
    timestamp: string;
    waterLevelLeft?: number;
    waterLevelRight?: number;
    waterLevelPercentage?: number;
    fillValveState?: number;
    drainValveState?: number;
    dripLineValveState?: number;
    fertigationValveState?: number;
    balanceValveState?: number;
    status?: string;
    temperatureLeft?: number;
    temperatureRight?: number;
    tankTemperatureLeft?: number;
    tankTemperatureRight?: number;
    moistureLeft?: number;
    moistureRight?: number;
    ecLeft?: number;
    ecRight?: number;
    varianceLeft?: number;
    varianceRight?: number;
    activeSection?: string;
    autoRegulate?: string;
    deviceId: number;
    imei: string;
    createdAt: string;
};

export type DeviceDataPointsResponse = {
    dataPoints: DataPoint[];
    totalCount: number;
    pageCount: number;
    currentPage: number;
};

const fetchDeviceDataPoints = async ({
    deviceId,
    take,
    page,
    from,
    to,
    signal
}: {
    deviceId?: string;
    take?: number;
    page?: number;
    from?: Date;
    to?: Date;
    signal?: AbortSignal;
}) => {
    try {
        const params = new URLSearchParams();
        if (from) params.append('from', from.toISOString());
        if (to) params.append('to', to.toISOString());
        if (take) params.append('take', take.toString());
        if (page !== undefined) params.append('page', page.toString());

        const response = await axiosClient.get<DeviceDataPointsResponse>(
            `${BASE_URL}/device/${deviceId}/datapoints`,
            {
                params,
                signal
            }
        );
        return response.data;
    } catch (err) {
        Sentry.captureException(err);
        throw err;
    }
};

export const useDeviceDataPoints = (
    deviceId?: string,
    take?: number,
    page?: number,
    from?: Date,
    to?: Date,
) => {
    const query = useQuery({
        queryKey: ['device-data-points', deviceId, take, page, from, to],
        queryFn: ({ signal }) => fetchDeviceDataPoints({
            deviceId,
            take,
            page,
            from,
            to,
            signal
        }),
        placeholderData: keepPreviousData,
        enabled: !!deviceId,
    });

    return query;
}; 
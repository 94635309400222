import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';
import { BooleanState } from '@/data/device';

export type DashboardDevice = {
  id: number;
  name: string;
  imei: string;
  type: string;
  status: string;
  needsMaintenance: boolean;
  lastDataTimestamp: number | null;
  organizationId: number;
  organizationName: string;
  lng: number;
  lat: number;
  autoRegulateState: BooleanState | null;
  waterLevelPercentage: number | null;
  fillValveState: number | null;
  drainValveState: number | null;
  dripLineValveState: number | null;
  temperatureLeft: number | null;
  temperatureRight: number | null;
  waterLevelLeft: number | null;
  waterLevelRight: number | null;
  moistureLeft: number | null;
  moistureRight: number | null;
  ecLeft: number | null;
  ecRight: number | null;
  firmwareVersion: string;
  activeSection: string | null;
  imageUrl: string | null;
  referenceSignal: number | null;
  uptime: number | null;
  resetReason: string | null;
};

const BASE_URL = '/dashboard/devices';

const fetchDashboardDevices = async ({ signal }: { signal?: AbortSignal } = {}) => {
  try {
    const response = await axiosClient.get<DashboardDevice[]>(BASE_URL, { signal });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const useDashboard = () => {
  return useQuery({
    queryKey: ['dashboard', 'devices'],
    queryFn: fetchDashboardDevices,
    refetchOnWindowFocus: true,
    refetchInterval: 60000
  });
}; 
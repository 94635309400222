import type { RouteObject } from 'react-router-dom'

import App from '@/App'
import AccountLayout from '@/components/v2/layouts/AccountLayout'
import AppLayout from '@/components/v2/layouts/AppLayout'
import SandAnalysisLayout from '@/components/public/layouts/SandAnalysisLayout'
import { adminV3Routes } from '@/admin-v3/routes'

import CreateAccountPage from './account/create-account'
import LoginPage from './account/login'
import PrivacyPolicyPage from './account/privacy-policy'
import ResetPasswordPage from './account/reset-password'
import SetPasswordPage from './account/set-password'
import TermsAndConditions from './account/terms-and-conditions'
import DataPage from './admin/data'
import DataDownlinksPage from './admin/data-downlink'
import AdminDevicesPage from './admin/devices'
import AdminDevicePage from './admin/device'
import FirmwarePage from './admin/firmware'
import MetadataPage from './admin/metadata'
import MetadataDownlinksPage from './admin/metadata-downlink'
import OrganizationsPage from './admin/organizations'
import QRCodePage from './admin/qrcode'
import UsersPage from './admin/users'
import ActivityPage from './app/activity'
import AddDevicePage from './app/add-device'
import AddDeviceRedirectPage from './app/add-device-redirect'
import ChangePasswordPage from './app/change-password'
import DashboardPage from './app/dashboard'
import DevicePage from './app/device'
import DeviceSettingsPage from './app/device-settings'
import OrganizationSettingsPage from './app/organization-settings'
import UserSettingsPage from './app/user-settings'
import CoverPage from './cover'
import ErrorPage from './error'
import DiagnosticsPage from './admin/diagnostics'
import DeviceTimelinePage from './admin/device-timeline'
import DiagnosticSchedulesPage from './admin/diagnosticSchedules'
import SandStandardsPage from './admin/sand-standards'
import EditSandStandardPage from './admin/edit-sand-standard'
import SandAnalysisLandingPage from './public/sand-analysis-landing'
import WizardDataPage from './public/wizard/wizard-data'
import WizardStandardPage from './public/wizard/wizard-standard'
import PreviousResultsPage from './public/previous-results'
import AdminV3Sidebar from '@/admin-v3/components/layout/AdminV3Sidebar'
import SalesSidebar from '@/sales-app/components/layout/SalesSidebar'
import { salesRoutes } from '@/sales-app/routes'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <CoverPage />,
      },
      {
        path: 'account',
        element: <AccountLayout />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'set-password/:id',
            element: <SetPasswordPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
          {
            path: 'create-account/:id',
            element: <CreateAccountPage />,
          },
          {
            path: 'terms-and-conditions',
            element: <TermsAndConditions />,
          },
          {
            path: 'privacy-policy',
            element: <PrivacyPolicyPage />,
          },
        ],
      },
      {
        path: 'app',
        element: <AppLayout orgUserAccessRole="member" />,
        children: [
          {
            path: ':orgId/users/:userId',
            element: <UserSettingsPage />,
          },
          {
            path: ':orgId/change-password',
            element: <ChangePasswordPage />,
          },
          {
            path: ':orgId',
            element: <DashboardPage />,
          },
          {
            path: ':orgId/settings',
            element: <OrganizationSettingsPage />,
          },
          {
            path: ':orgId/:deviceId',
            element: <DevicePage />,
          },
          {
            path: ':orgId/:deviceId/settings',
            element: <DeviceSettingsPage />,
          },
          {
            path: ':orgId/add-device',
            element: <AddDevicePage />,
          },
          {
            path: 'add-device/:imei',
            element: <AddDeviceRedirectPage />,
          },
          {
            path: ':orgId/activity',
            element: <ActivityPage />,
          },
        ],
      },
      {
        path: 'admin',
        element: <AppLayout userAccessRole="organization_admin" />,
        children: [
          {
            path: 'users',
            element: <UsersPage />,
          },
          {
            path: 'organizations',
            element: <OrganizationsPage />,
          },
          {
            path: 'devices',
            element: <AdminDevicesPage />,
          },
          {
            path: 'devices/:deviceId',
            element: <AdminDevicePage />,
          },
          {
            path: 'data',
            element: <DataPage />,
          },
          {
            path: 'metadata',
            element: <MetadataPage />,
          },
          {
            path: 'data-downlinks',
            element: <DataDownlinksPage />,
          },
          {
            path: 'metadata-downlinks',
            element: <MetadataDownlinksPage />,
          },
          {
            path: 'qr-code',
            element: <QRCodePage />,
          },
          {
            path: 'firmware',
            element: <FirmwarePage />,
          },
          {
            path: 'diagnostics',
            element: <DiagnosticsPage />,
          },
          {
            path: 'diagnostic-schedules',
            element: <DiagnosticSchedulesPage />,
          },
          {
            path: 'sand-standards',
            element: <SandStandardsPage />,
          },
          {
            path: 'sand-standards/:id',
            element: <EditSandStandardPage />,
          },
          {
            path: 'timeline',
            element: <DeviceTimelinePage />,
          },
        ],
      },
      {
        path: 'admin/v3',
        element: <AppLayout userAccessRole="admin" sidebar={AdminV3Sidebar} />,
        children: adminV3Routes
      },
      {
        path: 'sales',
        element: <AppLayout userAccessRole="admin" sidebar={SalesSidebar} />,
        children: salesRoutes
      },
      {
        path: 'sand-analysis',
        element: <SandAnalysisLayout />,
        children: [
          {
            index: true,
            element: <SandAnalysisLandingPage />,
          },
          {
            path: 'analyze/:sessionId/data',
            element: <WizardDataPage />,
          },
          {
            path: 'analyze/:sessionId/result',
            element: <WizardStandardPage />,
          },
          {
            path: 'previous',
            element: <PreviousResultsPage />,
          },
          {
            path: 'resources/guide',
            element: <div>Guide Page</div>,
          },
          {
            path: 'resources/standards',
            element: <div>Standards Page</div>,
          },
          {
            path: 'help',
            element: <div>Help Page</div>,
          },
          {
            path: 'tutorials',
            element: <div>Tutorials Page</div>,
          }
        ]
      }
    ],
  },
]

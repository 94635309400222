import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { PaginatedPeripherals, usePeripherals } from '../../../peripherals/hooks/usePeripherals';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { DevicePeripheral } from '../../hooks/useDevicePeripherals';


const formSchema = z.object({
  peripheralId: z.string().min(1, 'Peripheral type is required'),
  name: z.string().min(1, 'Name is required'),
  address: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  section: z.enum(['left', 'right', 'standalone']),
});

export type PeripheralFormValues = z.infer<typeof formSchema>;

interface PeripheralDialogProps {
  onAdd: (data: PeripheralFormValues) => Promise<void>;
  onUpdate?: (id: number, data: PeripheralFormValues) => Promise<void>;
  isAdding: boolean;
  isUpdating?: boolean;
  peripheral?: DevicePeripheral | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availablePeripherals: PaginatedPeripherals['items'];
}

const PeripheralTypeInfo = ({ peripheralId }: { peripheralId: string }) => {
  const { data: peripherals } = usePeripherals();
  const peripheral = peripherals?.items.find(type => Number(type.id) === Number(peripheralId));

  if (!peripheral) return null;

  return (
    <Card className="mt-2">
      <CardContent className="pt-4 text-sm space-y-4">
        <div>
          <span className="text-muted-foreground">Description:</span>
          <span className="ml-2">{peripheral.description}</span>
        </div>
        <div>
          <span className="text-muted-foreground">Manufacturer:</span>
          <span className="ml-2">{peripheral.manufacturer}</span>
        </div>
        {peripheral.technicalSpecifications && (
          <div>
            <span className="text-muted-foreground">Technical Specifications:</span>
            <span className="ml-2">{peripheral.technicalSpecifications}</span>
          </div>
        )}
        {peripheral.readings.length > 0 && (
          <div>
            <span className="text-muted-foreground">Readings:</span>
            <div className="mt-1 flex flex-wrap gap-1">
              {peripheral.readings.map((reading, index) => (
                <Badge key={index} variant="secondary">
                  {reading.type} (Index: {reading.arrayIndex})
                </Badge>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export const PeripheralDialog = ({
  onAdd,
  onUpdate,
  isAdding,
  isUpdating,
  peripheral,
  open,
  onOpenChange,
  availablePeripherals
}: PeripheralDialogProps) => {

  const form = useForm<PeripheralFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      peripheralId: '',
      name: '',
      address: 0,
      section: 'left',
    },
  });

  // Reset form when peripheral changes
  useEffect(() => {
    if (peripheral) {
      form.reset({
        peripheralId: peripheral.peripheral?.id.toString() ?? '',
        name: peripheral.name,
        address: peripheral.address,
        section: peripheral.section,
      });
    } else {
      form.reset({
        peripheralId: '',
        name: '',
        address: 0,
        section: 'left',
      });
    }
  }, [peripheral, form]);

  const selectedPeripheralTypeId = form.watch('peripheralId');

  // Auto-populate name when peripheral type is selected (only for new peripherals)
  useEffect(() => {
    if (selectedPeripheralTypeId && !peripheral) {
      const peripheral = availablePeripherals?.find(
        type => type.id.toString() === selectedPeripheralTypeId
      );
      if (peripheral && !form.getValues('name')) {
        form.setValue('name', peripheral.name);
      }
    }
  }, [selectedPeripheralTypeId, availablePeripherals, form, peripheral]);

  const onSubmit = async (data: PeripheralFormValues) => {
    try {
      if (peripheral) {
        await onUpdate?.(peripheral.id, data);
      } else {
        await onAdd(data);
      }
      onOpenChange(false);
      form.reset();
    } catch (error) {
      // Error will be handled by the mutation
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{peripheral ? 'Update Peripheral' : 'Add New Peripheral'}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="peripheralId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Peripheral Type</FormLabel>
                  <Select
                    value={field.value.toString()}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a peripheral type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {availablePeripherals.map((type) => (
                        <SelectItem key={type.id.toString()} value={type.id.toString()}>
                          {type.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedPeripheralTypeId && (
              <PeripheralTypeInfo peripheralId={selectedPeripheralTypeId} />
            )}

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name (auto-filled, can be modified)</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter peripheral name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter peripheral address" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="section"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Section</FormLabel>
                  <FormControl>
                    <ToggleGroup
                      type="single"
                      value={field.value.toString()}
                      onValueChange={(value) => field.onChange(value)}
                      className="justify-start rounded-lg p-1"
                    >
                      <ToggleGroupItem
                        value="left"
                        aria-label="Left section"
                        className="data-[state=on]:bg-sand data-[state=on]:border"
                      >
                        Left
                      </ToggleGroupItem>
                      <ToggleGroupItem
                        value="right"
                        aria-label="Right section"
                        className="data-[state=on]:bg-sand data-[state=on]:border"
                      >
                        Right
                      </ToggleGroupItem>
                      <ToggleGroupItem
                        value="standalone"
                        aria-label="Standalone section"
                        className="data-[state=on]:bg-sand data-[state=on]:border"
                      >
                        Standalone
                      </ToggleGroupItem>
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => onOpenChange(false)}
                disabled={isAdding || isUpdating}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isAdding || isUpdating}
                isLoading={isAdding || isUpdating}
              >
                {peripheral ? 'Update' : 'Add'} Peripheral
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}; 
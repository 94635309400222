import { Thermometer, Waves, Droplets, Zap } from "lucide-react";
import { useDeviceMapping, type PeripheralFunction, type PeripheralMapping } from "../../hooks/useDeviceMapping";
import { DeviceLatestData, useDeviceLatestData } from "../../hooks/useDeviceLatestData";
import { MappingCard } from "./MappingCard";
import { MappingDialog } from "./MappingDialog";
import { useState, useMemo, ReactNode } from "react";
import { Section } from "@/admin-v3/models/types";
import { ActuatorCard } from "./ActuatorCard";
import ValveStateIcon from "@/components/v2/icon-resolvers/DeviceValveIcon";
import { DevicePeripheral, useDevicePeripherals } from "../../hooks/useDevicePeripherals";


export type PeripheralConfig = {
  label: string;
  devicePeripheralId: number | undefined;
  devicePeripheralIndex: number | undefined;
  devicePeripheral: DevicePeripheral | undefined;
};

export type MappingCardProps = {
  title: string;
  icon: ReactNode;
  leftPeripheral: PeripheralConfig;
  rightPeripheral: PeripheralConfig;
  leftValue?: number;
  rightValue?: number;
  peripheralFunction: PeripheralFunction;
  onMapClick: (side: Section) => void;
  onEditClick: (side: Section) => void;
};

type DialogConfig = {
  function: PeripheralFunction;
  section: Section;
} | null;

type MappingKey = `${PeripheralFunction}_${Section}`;

export const DeviceMappingOverview = ({ deviceId }: { deviceId: string }) => {
  const { data: mapping, isLoading: isMappingLoading } = useDeviceMapping(deviceId);
  const { peripherals, isLoading: isPeripheralsLoading } = useDevicePeripherals(deviceId);
  const { data: latestData, isLoading: isLatestDataLoading } = useDeviceLatestData(deviceId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<DialogConfig>(null);

  // Create a dictionary of peripheral mappings for faster lookup
  const peripheralMappingsDict = useMemo(() => {
    if (!mapping?.peripheralMappings) return {} as Record<MappingKey, PeripheralMapping>;

    return mapping.peripheralMappings.reduce<Record<MappingKey, PeripheralMapping>>(
      (acc, sm) => {
        acc[`${sm.function}_${sm.section}`] = sm;
        return acc;
      },
      {} as Record<MappingKey, PeripheralMapping>
    );
  }, [mapping?.peripheralMappings]);

  // Create a dictionary of peripherals for faster lookup
  const peripheralsDict = useMemo(() => {
    if (!peripherals) return {} as Record<number, DevicePeripheral>;

    return peripherals.reduce<Record<number, DevicePeripheral>>(
      (acc, peripheral) => {
        acc[peripheral.id] = peripheral;
        return acc;
      },
      {} as Record<number, DevicePeripheral>
    );
  }, [peripherals]);

  if (isMappingLoading || isPeripheralsLoading || isLatestDataLoading) {
    return <div>Loading...</div>;
  }

  const getPeripheralConfig = (func: PeripheralFunction, section: Section): PeripheralConfig => {
    const key = `${func}_${section}` as MappingKey;
    const peripheralMapping = peripheralMappingsDict[key];
    const label = `${section.charAt(0).toUpperCase() + section.slice(1)} ${func.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;

    if (!peripheralMapping) {
      return {
        label,
        devicePeripheralId: undefined,
        devicePeripheralIndex: undefined,
        devicePeripheral: undefined,
      };
    }

    return {
      label,
      devicePeripheralId: peripheralMapping.devicePeripheralId,
      devicePeripheralIndex: peripheralMapping.index,
      devicePeripheral: peripheralsDict[peripheralMapping.devicePeripheralId],
    };
  };

  const handleMapClick = (section: Section, func: PeripheralFunction) => {
    setSelectedMapping({ function: func, section });
    setIsDialogOpen(true);
  };

  const handleEditClick = (section: Section, func: PeripheralFunction) => {
    setSelectedMapping({ function: func, section });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedMapping(null);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4 mt-6">
        <div>
          <h2 className="text-lg font-semibold text-slate-900">Device Mapping</h2>
        </div>
      </div>

      {/* Main container using flex-wrap */}
      <div className="flex flex-wrap gap-4">
        <MappingCard
          title="Water Level"
          icon={<Waves className="h-4 w-4" />}
          leftPeripheral={getPeripheralConfig('water_level', 'left')}
          rightPeripheral={getPeripheralConfig('water_level', 'right')}
          leftValue={latestData?.water_level?.left}
          rightValue={latestData?.water_level?.right}
          peripheralFunction="water_level"
          onMapClick={(side) => handleMapClick(side, 'water_level')}
          onEditClick={(side) => handleEditClick(side, 'water_level')}
        />

        <MappingCard
          title="Temperature"
          icon={<Thermometer className="h-4 w-4" />}
          leftPeripheral={getPeripheralConfig('temperature', 'left')}
          rightPeripheral={getPeripheralConfig('temperature', 'right')}
          leftValue={latestData?.temperature?.left}
          rightValue={latestData?.temperature?.right}
          peripheralFunction="temperature"
          onMapClick={(side) => handleMapClick(side, 'temperature')}
          onEditClick={(side) => handleEditClick(side, 'temperature')}
        />

        <MappingCard
          title="Tank Temperature"
          icon={<Thermometer className="h-4 w-4" />}
          leftPeripheral={getPeripheralConfig('tank_temperature', 'left')}
          rightPeripheral={getPeripheralConfig('tank_temperature', 'right')}
          leftValue={latestData?.tank_temperature?.left}
          rightValue={latestData?.tank_temperature?.right}
          peripheralFunction="tank_temperature"
          onMapClick={(side) => handleMapClick(side, 'tank_temperature')}
          onEditClick={(side) => handleEditClick(side, 'tank_temperature')}
        />

        <MappingCard
          title="Moisture"
          icon={<Droplets className="h-4 w-4" />}
          leftPeripheral={getPeripheralConfig('moisture', 'left')}
          rightPeripheral={getPeripheralConfig('moisture', 'right')}
          leftValue={latestData?.moisture?.left}
          rightValue={latestData?.moisture?.right}
          peripheralFunction="moisture"
          onMapClick={(side) => handleMapClick(side, 'moisture')}
          onEditClick={(side) => handleEditClick(side, 'moisture')}
        />

        <MappingCard
          title="EC"
          icon={<Zap className="h-4 w-4" />}
          leftPeripheral={getPeripheralConfig('ec', 'left')}
          rightPeripheral={getPeripheralConfig('ec', 'right')}
          leftValue={latestData?.ec?.left}
          rightValue={latestData?.ec?.right}
          peripheralFunction="ec"
          onMapClick={(side) => handleMapClick(side, 'ec')}
          onEditClick={(side) => handleEditClick(side, 'ec')}
        />

        {/* Actuator cards using flex-wrap */}
        {[
          { label: "Fill Valve", function: "fill_valve" },
          { label: "Drain Valve", function: "drain_valve" },
          { label: "Drip Line Valve", function: "drip_line_valve" },
          { label: "Fertigation Valve", function: "fertigation_valve" },
          { label: "Balance Valve", function: "balance_valve" }
        ].map((actuator) => (
          <ActuatorCard
            key={actuator.function}
            title={actuator.label}
            icon={<ValveStateIcon />}
            position="standalone"
            leftPeripheral={getPeripheralConfig(actuator.function as PeripheralFunction, 'standalone')}
            leftValue={latestData?.[actuator.function as keyof DeviceLatestData]?.standalone}
            peripheralFunction={actuator.function as PeripheralFunction}
            onMapClick={(section) => handleMapClick(section, actuator.function as PeripheralFunction)}
            onEditClick={(section) => handleEditClick(section, actuator.function as PeripheralFunction)}
          />
        ))}
      </div>


      {isDialogOpen && selectedMapping ? (
        <MappingDialog
          deviceId={Number(deviceId)}
          function={selectedMapping.function}
          section={selectedMapping.section}
          mapping={mapping}
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
        />
      ) : null}
    </>
  );
}; 
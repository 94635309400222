import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';

const BASE_URL = '/admin/device-rpcs';
const POLL_INTERVAL = 10000; // 10 seconds

export type DeviceRpcType = 'fetch_config' | 'reboot';

export type DeviceRpcDTO = {
  id: number;
  type: DeviceRpcType;
  status: 'pending' | 'downlinked' | 'executed' | 'failed';
  createdAt: string;
  downlinkedAt: string | null;
  executedAt: string | null;
};

type DeviceRpcResponse = {
  rpcs: DeviceRpcDTO[];
  total: number;
};

export const useDeviceRpcs = (deviceId: string) => {
  const queryClient = useQueryClient();

  const {
    data: rpcsData,
    isLoading: isLoadingRpcs,
    error: rpcsError,
  } = useQuery({
    queryKey: ['device', deviceId, 'rpcs'],
    queryFn: async ({ signal }) => {
      try {
        const response = await axiosClient.get<DeviceRpcResponse>(
          `${BASE_URL}/${deviceId}`,
          { signal }
        );
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    refetchInterval: POLL_INTERVAL,
  });

  const { mutate: sendRpc, isPending: isSendingRpc } = useMutation({
    mutationFn: async (type: DeviceRpcType) => {
      try {
        const response = await axiosClient.post<DeviceRpcDTO>(
          BASE_URL,
          { deviceId, type }
        );
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['device', deviceId, 'rpcs'] });
    },
  });

  const hasPendingRpc = (type: DeviceRpcType): boolean => {
    if (!rpcsData?.rpcs) return false;
    return rpcsData.rpcs.some(
      (rpc) => 
        rpc.type.toLowerCase() === type.toLowerCase() && 
        rpc.status.toLowerCase() === 'pending'
    );
  };

  return {
    rpcs: rpcsData?.rpcs ?? [],
    totalRpcs: rpcsData?.total ?? 0,
    isLoadingRpcs,
    rpcsError,
    sendRpc,
    isSendingRpc,
    hasPendingRpc,
  };
}; 
import { PeripheralCard } from './PeripheralCard';
import { DeviceMappingOverview } from '../mappings/DeviceMappingOverview';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { PeripheralDialog, PeripheralFormValues } from './PeripheralDialog';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { usePeripherals } from '@/admin-v3/features/peripherals/hooks/usePeripherals';
import { useDevicePeripherals } from '../../hooks/useDevicePeripherals';
import { DevicePeripheral } from '../../hooks/useDevicePeripherals';

interface DevicePeripheralsProps {
  deviceId: string;
}

export const DevicePeripherals = ({ deviceId }: DevicePeripheralsProps) => {
  const [selectedPeripheral, setSelectedPeripheral] = useState<DevicePeripheral | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    peripherals,
    isLoading,
    error,
    createPeripheral,
    isCreating,
    deletePeripheral,
    isDeleting,
    updatePeripheral,
  } = useDevicePeripherals(deviceId);

  const peripheralInventory = usePeripherals();

  const handleAdd = async (data: PeripheralFormValues) => {
    await createPeripheral({
      ...data,
      deviceId,
    });
    setDialogOpen(false);
  };

  const handleEdit = async (id: number, data: PeripheralFormValues) => {
    await updatePeripheral({
      ...data,
      id,
    });
  };

  const handleEditClick = (peripheral: DevicePeripheral) => {
    setSelectedPeripheral(peripheral);
    setDialogOpen(true);
  };

  const handleAddClick = () => {
    setSelectedPeripheral(null);
    setDialogOpen(true);
  };

  const handleDelete = (peripheral: DevicePeripheral) => {
    deletePeripheral(peripheral.id);
  };

  if (isLoading) {
    return <div>Loading peripherals...</div>;
  }

  if (error) {
    return <div>Error loading peripherals</div>;
  }

  return (
    <div className="space-y-6">
      {/* Mapping Overview */}
      <DeviceMappingOverview deviceId={deviceId} />

      {/* Peripherals Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-lg font-semibold text-slate-900">Device Peripherals</h2>
            <p className="text-sm text-slate-500">
              Configure peripherals and their readings for this device
            </p>
          </div>
          <Button onClick={handleAddClick} size="sm">
            <Plus className="h-4 w-4 mr-2" />
            Add Peripheral
          </Button>
        </div>

        {dialogOpen ? (
          <PeripheralDialog
            onAdd={handleAdd}
            onUpdate={handleEdit}
            isAdding={isCreating}
            isUpdating={isLoading}
            peripheral={selectedPeripheral}
            open={dialogOpen}
            onOpenChange={setDialogOpen}
            availablePeripherals={peripheralInventory.data?.items ?? []}
          />
        ) : null}

        {/* Peripheral Grid */}
        {peripherals.length === 0 ? (
          <div className="text-center py-8 text-slate-500 bg-slate-50 rounded-lg border border-slate-200">
            No peripherals configured for this device
          </div>
        ) : (
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {peripherals.map((peripheral) => (
              <AlertDialog key={peripheral.id}>
                <PeripheralCard
                  peripheral={peripheral}
                  onEdit={handleEditClick}
                  onDelete={handleDelete}
                />
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete Peripheral</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to delete this peripheral? This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => handleDelete(peripheral)}
                      className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                      disabled={isDeleting}
                    >
                      {isDeleting ? 'Deleting...' : 'Delete'}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}; 
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Pencil, Plus } from "lucide-react";
import { type MappingCardProps, type PeripheralConfig } from "./device-mapping-types";
import { type PeripheralValue } from "../../hooks/useDeviceLatestData";
import { formatDistanceToNow } from "date-fns";

const formatValue = (value: number, peripheralFunction: string) => {
  switch (peripheralFunction) {
    case 'water_level':
      return `${value} mm`;
    case 'temperature':
    case 'tank_temperature':
      return `${value.toFixed(1)}°C`;
    case 'moisture':
      return `${value.toFixed(1)}%`;
    case 'ec':
      return `${value.toFixed(2)} mS/cm`;
    default:
      return `${value}`;
  }
};

const PeripheralInfo = ({
  config,
  value,
  peripheralFunction,
  onMap,
  onEdit
}: {
  config: PeripheralConfig;
  value?: PeripheralValue;
  peripheralFunction: string;
  onMap: () => void;
  onEdit: () => void;
}) => {
  if (!config.devicePeripheral) {
    return (
      <div className="flex items-center justify-between py-1">
        <span className="text-xs text-slate-400 font-light">Configure peripheral</span>
        <Button
          variant="ghost"
          size="sm"
          onClick={onMap}
          className="text-slate-500 hover:text-slate-800 h-6 px-2"
        >
          <Plus className="h-3 w-3 mr-1" />
          Add
        </Button>
      </div>
    );
  }

  return (
    <div className="group py-1">
      <div className="flex items-start justify-between">
        <div className="space-y-0.5">
          <div className="flex items-center gap-1">
            <span className="text-sm font-medium text-slate-700">{config.devicePeripheral.name}</span>
            <Button
              variant="ghost"
              size="sm"
              onClick={onEdit}
              className="text-slate-400 hover:text-slate-800 h-5 px-1 ml-1"
            >
              <Pencil className="h-2.5 w-2.5" />
            </Button>
          </div>
          <p className="text-xs text-slate-500">
            {config.devicePeripheral.address} @ {config.devicePeripheralIndex}
          </p>
          {value && (
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-slate-900">
                {formatValue(value.value, peripheralFunction)}
              </span>
              <span className="text-xs text-slate-500">
                {formatDistanceToNow(new Date(value.timestamp), { addSuffix: true })}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const MappingCard = ({
  title,
  icon,
  leftPeripheral,
  rightPeripheral,
  leftValue,
  rightValue,
  peripheralFunction,
  onMapClick,
  onEditClick,
}: MappingCardProps) => {
  return (
    <Card className="p-2 hover:shadow-md transition-shadow duration-200 w-[calc(50%-0.5rem)] md:w-[calc(33.333%-0.67rem)] lg:w-[calc(25%-0.75rem)]">
      <CardHeader className="p-0 pb-2 px-0 flex flex-row items-center justify-center space-y-0">
        <CardTitle className="text-sm text-slate-500 font-normal flex items-center gap-1">
          {icon}
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="px-0 pt-1">
        <div className="flex relative">
          {/* Left Side */}
          <div className="flex-1 px-2">
            <PeripheralInfo
              config={leftPeripheral}
              value={leftValue}
              peripheralFunction={peripheralFunction}
              onMap={() => onMapClick('left')}
              onEdit={() => onEditClick('left')}
            />
          </div>

          {/* Centered Divider */}
          <div className="absolute top-0 bottom-0 left-1/2 -ml-[0.5px] w-[1px] bg-slate-200" />

          {/* Right Side */}
          <div className="flex-1 px-2">
            <PeripheralInfo
              config={rightPeripheral}
              value={rightValue}
              peripheralFunction={peripheralFunction}
              onMap={() => onMapClick('right')}
              onEdit={() => onEditClick('right')}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}; 
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ArrowLeft, Save, Undo } from 'lucide-react';
import { BASE_MATERIALS } from './data/baseMaterials';
import { 
  getAllMaterialsWithSettings, 
  updateMaterialSettings, 
  resetMaterialSettings 
} from './services/materialSettingsService';

export default function MaterialSettingsPage() {
  const [materials, setMaterials] = useState(() => getAllMaterialsWithSettings(BASE_MATERIALS));
  const [editValues, setEditValues] = useState<Record<string, { costPerUnit: number, markup: number }>>({});
  const navigate = useNavigate();

  // Check if a material is currently being edited
  const isEditing = (materialId: string): boolean => {
    return !!editValues[materialId];
  };

  // Start editing a material
  const handleEdit = (materialId: string, costPerUnit: number, markup: number) => {
    setEditValues({
      ...editValues,
      [materialId]: { costPerUnit, markup }
    });
  };

  // Update the costPerUnit value while editing
  const handleCostChange = (materialId: string, value: number) => {
    setEditValues({
      ...editValues,
      [materialId]: { 
        ...editValues[materialId], 
        costPerUnit: value 
      }
    });
  };

  // Update the markup value while editing
  const handleMarkupChange = (materialId: string, value: number) => {
    setEditValues({
      ...editValues,
      [materialId]: { 
        ...editValues[materialId], 
        markup: value 
      }
    });
  };

  // Save changes for a material
  const handleSave = (materialId: string) => {
    const { costPerUnit, markup } = editValues[materialId];
    
    updateMaterialSettings(materialId, {
      costPerUnit,
      markup
    });
    
    // Clear editing state for this material
    const newEditValues = { ...editValues };
    delete newEditValues[materialId];
    setEditValues(newEditValues);
    
    // Refresh materials list with new settings
    setMaterials(getAllMaterialsWithSettings(BASE_MATERIALS));
  };

  // Cancel editing a material
  const handleCancel = (materialId: string) => {
    const newEditValues = { ...editValues };
    delete newEditValues[materialId];
    setEditValues(newEditValues);
  };

  // Reset a material to default values
  const handleReset = (materialId: string) => {
    resetMaterialSettings(materialId);
    
    // Clear any editing state
    const newEditValues = { ...editValues };
    delete newEditValues[materialId];
    setEditValues(newEditValues);
    
    // Refresh materials list
    setMaterials(getAllMaterialsWithSettings(BASE_MATERIALS));
  };

  return (
    <div className="container mx-auto p-6">
      <div className="mb-4">
        <Button variant="ghost" onClick={() => navigate('/sales/quotes')}>
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Quotes
        </Button>
      </div>
      
      <div className="mb-6">
        <h1 className="text-3xl font-bold">Material Settings</h1>
        <p className="text-gray-500 mt-2">
          Adjust cost per unit and markup values for materials. Changes will apply to new quotes only.
        </p>
      </div>
      
      <Card>
        <CardHeader>
          <CardTitle>Material Costs & Markups</CardTitle>
          <CardDescription>
            Customized values are used when creating new quotes. Existing quotes will keep their original values.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Material</TableHead>
                <TableHead>Unit</TableHead>
                <TableHead className="text-right">Cost Per Unit</TableHead>
                <TableHead className="text-right">Markup (%)</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {materials.map(material => (
                <TableRow key={material.id} onClick={() => !isEditing(material.id) && handleEdit(material.id, material.costPerUnit, material.markup)}>
                  <TableCell className="font-medium">{material.name}</TableCell>
                  <TableCell>{material.unit}</TableCell>
                  
                  {isEditing(material.id) ? (
                    <>
                      <TableCell className="text-right">
                        <Input 
                          type="number" 
                          min="0"
                          step="0.01"
                          value={editValues[material.id].costPerUnit} 
                          onChange={e => handleCostChange(material.id, parseFloat(e.target.value) || 0)}
                          className="w-24 ml-auto"
                        />
                      </TableCell>
                      <TableCell className="text-right">
                        <Input 
                          type="number" 
                          min="0"
                          step="1"
                          value={editValues[material.id].markup} 
                          onChange={e => handleMarkupChange(material.id, parseInt(e.target.value) || 0)}
                          className="w-24 ml-auto"
                        />
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex gap-2 justify-end">
                          <Button size="sm" onClick={() => handleSave(material.id)}>
                            <Save className="h-4 w-4 mr-1" />
                            Save
                          </Button>
                          <Button size="sm" variant="outline" onClick={() => handleCancel(material.id)}>
                            Cancel
                          </Button>
                        </div>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className="text-right">
                        {material.costPerUnit.toFixed(2)}
                      </TableCell>
                      <TableCell className="text-right">
                        {material.markup}%
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex gap-2 justify-end">
                          <Button size="sm" onClick={() => handleEdit(material.id, material.costPerUnit, material.markup)}>
                            Edit
                          </Button>
                          <Button 
                            size="sm" 
                            variant="outline" 
                            onClick={() => handleReset(material.id)}
                            title="Reset to default values"
                          >
                            <Undo className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
} 
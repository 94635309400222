import { BaseMaterial } from '../data/baseMaterials';

interface MaterialSettings {
  costPerUnit?: number;
  markup?: number;
}

// Storage key for material settings in localStorage
const STORAGE_KEY = 'flowtrack_material_settings';

// Get user-modified settings for all materials
export const getMaterialSettings = (): Record<string, MaterialSettings> => {
  try {
    const settings = localStorage.getItem(STORAGE_KEY);
    return settings ? JSON.parse(settings) : {};
  } catch (error) {
    console.error('Failed to load material settings:', error);
    return {};
  }
};

// Update settings for a specific material
export const updateMaterialSettings = (
  materialId: string, 
  settings: MaterialSettings
): void => {
  try {
    const allSettings = getMaterialSettings();
    allSettings[materialId] = {
      ...allSettings[materialId],
      ...settings
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(allSettings));
  } catch (error) {
    console.error('Failed to save material settings:', error);
  }
};

// Reset settings for a specific material (remove customizations)
export const resetMaterialSettings = (materialId: string): void => {
  try {
    const allSettings = getMaterialSettings();
    if (allSettings[materialId]) {
      delete allSettings[materialId];
      localStorage.setItem(STORAGE_KEY, JSON.stringify(allSettings));
    }
  } catch (error) {
    console.error('Failed to reset material settings:', error);
  }
};

// Get a material with user settings applied
export const getMaterialWithUserSettings = (
  baseMaterial: BaseMaterial
): BaseMaterial => {
  const settings = getMaterialSettings()[baseMaterial.id] || {};
  
  return {
    ...baseMaterial,
    costPerUnit: settings.costPerUnit !== undefined ? settings.costPerUnit : baseMaterial.costPerUnit,
    markup: settings.markup !== undefined ? settings.markup : baseMaterial.markup
  };
};

// Get all materials with user settings applied
export const getAllMaterialsWithSettings = (
  baseMaterials: BaseMaterial[]
): BaseMaterial[] => {
  return baseMaterials.map(material => getMaterialWithUserSettings(material));
}; 
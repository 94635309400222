import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import type { Peripheral } from '../hooks/usePeripherals'

export const useColumns = (onEdit: (id: number) => void) => {

  const columns: ColumnDef<Peripheral>[] = [
    {
      id: 'actions',
      cell: ({ row }) => {
        const { id } = row.original
        return (
          <div className="flex gap-2">
            <Button
              variant="outline"
              type="button"
              className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
              onClick={() => onEdit(id)}
            >
              <span className="sr-only">Edit component</span>
              <Pencil2Icon className="h-4 w-4" />
            </Button>
          </div>
        )
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => {
        const { type } = row.original
        return (
          <Badge
            variant={type === 'sensor' ? 'default' : 'secondary'}
            className="capitalize"
          >
            {type}
          </Badge>
        )
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      accessorKey: 'manufacturer',
      header: 'Manufacturer',
    },
    {
      accessorKey: 'technicalSpecifications',
      header: 'Technical Specifications',
    },
    {
      id: 'readings',
      header: 'Readings',
      cell: ({ row }) => {
        const { readings } = row.original

        return (
          <div className="flex flex-wrap gap-1">
            {readings.map((reading, index) => (
              <Badge key={index} variant="secondary">
                {reading.type} ({reading.arrayIndex})
              </Badge>
            ))}
          </div>
        )
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: ({ row }) => {
        const { createdAt } = row.original
        return (
          <span className="whitespace-nowrap">
            {dateWithTimeInIsoFormat(new Date(createdAt))}
          </span>
        )
      },
    },
  ]

  return columns
} 
import { LineItem } from '../types';
import LineItemRow from './LineItemRow';

interface LineItemTableProps {
    items: LineItem[];
    onItemChange?: (id: string, item: LineItem) => void;
    onItemRemove?: (id: string) => void;
    showComments: boolean;
}

export function LineItemTable({
    items,
    onItemChange,
    onItemRemove,
    showComments
}: LineItemTableProps) {
    // Handle immediate item change
    const handleItemChange = (id: string, item: LineItem) => {
        // Trigger debounced update to parent
        onItemChange?.(id, item);
    }

    // Calculate totals
    const totals = items.reduce((acc, item) => ({
        cost: acc.cost + item.cost,
        sales: acc.sales + item.sales,
        profit: acc.profit + item.profit
    }), { cost: 0, sales: 0, profit: 0 });

    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num);
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-slate-100">
                        {onItemRemove && <th className="border px-2 py-1 text-left"></th>}
                        <th className="border px-2 py-1 text-left text-xs font-medium">Name</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Units</th>
                        <th className="border px-2 py-1 text-left text-xs font-medium">Unit</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Cost / Unit</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Cost</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Markup (%)</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Sales / Unit</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Sales</th>
                        <th className="border px-2 py-1 text-right text-xs font-medium">Profit</th>
                        {showComments && <th className="border px-2 py-1 text-left text-xs font-medium">Comment</th>}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <LineItemRow
                            key={item.id}
                            item={item}
                            onItemChange={handleItemChange}
                            onItemRemove={onItemRemove}
                            showComments={showComments}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="border-t">
                        {/* Colspan for delete button if present */}
                        {onItemRemove && <td className="px-2 py-1"></td>}
                        {/* Name column */}
                        <td className="px-2 py-1 text-xs text-slate-500">Total</td>
                        {/* Empty cells for Units, Unit, Cost/Unit */}
                        <td className="px-2 py-1"></td>
                        <td className="px-2 py-1"></td>
                        <td className="px-2 py-1"></td>
                        {/* Cost total */}
                        <td className="px-2 py-1 text-right text-slate-600">
                            {formatNumber(totals.cost)}
                        </td>
                        {/* Empty cell for Markup */}
                        <td className="px-2 py-1"></td>
                        {/* Empty cell for Sales/Unit */}
                        <td className="px-2 py-1"></td>
                        {/* Sales total */}
                        <td className="px-2 py-1 text-right text-slate-600">
                            {formatNumber(totals.sales)}
                        </td>
                        {/* Profit total */}
                        <td className="px-2 py-1 text-right text-slate-600">
                            {formatNumber(totals.profit)}
                        </td>
                        {/* Empty cell for Comment if shown */}
                        {showComments && <td className="px-2 py-1"></td>}
                    </tr>
                </tfoot>
            </table>
        </div>
    )
} 
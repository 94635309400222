import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Section } from '@/admin-v3/models/types';

const BASE_URL = '/admin/device-mappings';

export type PeripheralFunction = 'water_level' | 'temperature' | 'tank_temperature' | 'moisture' | 'ec' | 'fill_valve' | 'drain_valve' | 'drip_line_valve' | 'fertigation_valve' | 'balance_valve';

export type PeripheralMapping = {
  id: number;
  function: PeripheralFunction;
  section: Section;
  devicePeripheralId: number;
  index?: number;
};

export type DeviceMapping = {
  id: number;
  deviceId: number;
  peripheralMappings: PeripheralMapping[];
  // TODO: Add when DeviceActuator is implemented
  // actuatorMappings: ActuatorMapping[];
  createdAt: string;
};

export type CreateDeviceMappingCommand = {
  deviceId: number;
  peripheralMappings: Omit<PeripheralMapping, 'id'>[];
};

export type UpdateDeviceMappingCommand = {
  peripheralMappings: Omit<PeripheralMapping, 'id'>[];
};

export type SetMappingCommand = {
  deviceId: number;
  devicePeripheralId: number;
  function: PeripheralFunction;
  section: Section;
  index?: number;
};

export const useDeviceMapping = (deviceId: string) => {
  return useQuery({
    queryKey: ['device-mapping', deviceId],
    queryFn: async ({ signal }) => {
      try {
        const response = await axiosClient.get<DeviceMapping>(
          `${BASE_URL}/device/${deviceId}`,
          { signal }
        );
        return response.data;
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 404) {
          return null;
        }
        Sentry.captureException(err);
        throw err;
      }
    },
  });
};

export const useCreateDeviceMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateDeviceMappingCommand) => {
      try {
        const response = await axiosClient.post<DeviceMapping>(BASE_URL, data);
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['device-mapping', data.deviceId.toString()], data);
    },
  });
};

export const useUpdateDeviceMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number;
      data: UpdateDeviceMappingCommand;
    }) => {
      try {
        const response = await axiosClient.put<DeviceMapping>(
          `${BASE_URL}/${id}`,
          data
        );
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['device-mapping', data.deviceId.toString()], data);
    },
  });
};

export const useSetMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SetMappingCommand) => {
      try {
        await axiosClient.post(`${BASE_URL}/set`, data);
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['device-mapping', variables.deviceId.toString()]
      });
    },
  });
}; 
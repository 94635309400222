import { useMemo } from 'react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import PaginatedTable from '@/components/v2/PaginatedTable';
import TableDateSelect from '@/components/v2/selects/TableDateSelect';
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { NumberParam, withDefault, DateTimeParam, StringParam, useQueryParam } from 'use-query-params';
import { useDeviceMetadata, MetadataPoint } from '../../hooks/useDeviceMetadata';

interface DeviceMetadataProps {
    deviceId: string;
}

export const DeviceMetadata = ({ deviceId }: DeviceMetadataProps) => {
    const { t } = useTranslation(['admin']);
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
    const [pageSize, setPageSize] = useQueryParam(
        'pageSize',
        withDefault(NumberParam, 20),
    );
    const [from] = useQueryParam('from', DateTimeParam);
    const [to] = useQueryParam('to', DateTimeParam);

    const { data, isLoading, error } = useDeviceMetadata(
        deviceId,
        pageSize,
        page,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined
    );

    const columns = useMemo<ColumnDef<MetadataPoint>[]>(() => {
        return [
            {
                accessorKey: 'createdAt',
                header: t('data_points.timestamp', 'Timestamp'),
                cell: ({ row }) => {
                    const { createdAt } = row.original;
                    if (!createdAt) return null;

                    return (
                        <span className="whitespace-nowrap">
                            {dateWithTimeInIsoFormat(new Date(createdAt))}
                        </span>
                    );
                },
            },
            {
                accessorKey: 'imei',
                header: t('metadata.imei', 'IMEI'),
            },
            {
                accessorKey: 'justBooted',
                header: t('metadata.just_booted', 'Just Booted'),
                cell: ({ row }) => {
                    return <span>{row.original.justBooted ? 'Yes' : 'No'}</span>;
                },
            },
            {
                accessorKey: 'resetReason',
                header: t('metadata.reset_reason', 'Reset Reason'),
            },
            {
                accessorKey: 'uptime',
                header: t('metadata.uptime', 'Uptime (s)'),
                cell: ({ row }) => {
                    // Format uptime in human-readable form
                    const uptime = row.original.uptime;
                    const days = Math.floor(uptime / 86400);
                    const hours = Math.floor((uptime % 86400) / 3600);
                    const minutes = Math.floor((uptime % 3600) / 60);
                    const seconds = uptime % 60;

                    if (days > 0) {
                        return `${days}d ${hours}h ${minutes}m`;
                    } else if (hours > 0) {
                        return `${hours}h ${minutes}m ${seconds}s`;
                    } else if (minutes > 0) {
                        return `${minutes}m ${seconds}s`;
                    } else {
                        return `${seconds}s`;
                    }
                },
            },
            {
                accessorKey: 'firmwareVersion',
                header: t('metadata.firmware_version', 'Firmware Version'),
            },
            {
                accessorKey: 'modemFwVersion',
                header: t('metadata.modem_fw_version', 'Modem Firmware'),
            },
            {
                accessorKey: 'referenceSignal',
                header: t('metadata.reference_signal', 'Reference Signal'),
            },
            {
                accessorKey: 'errorStatus',
                header: t('metadata.error_status', 'Error Status'),
                cell: ({ row }) => {
                    const errorStatus = row.original.errorStatus;
                    if (errorStatus === undefined || errorStatus === null) return null;

                    // Display as hex for easier reading
                    return <span>0x{errorStatus.toString(16).toUpperCase()}</span>;
                },
            },
            {
                accessorKey: 'cycleTime',
                header: t('metadata.cycle_time', 'Cycle Time'),
            }
        ];
    }, [t]);

    const table = useReactTable({
        data: data?.metadataPoints || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        pageCount: data?.pageCount || 0,
        state: {
            pagination: {
                pageIndex: page,
                pageSize: pageSize,
            },
        },
        onPaginationChange: (updater) => {
            // make sure updater is callable (to avoid typescript warning)
            if (typeof updater !== 'function') return;

            const nextState = updater({ pageIndex: page, pageSize: pageSize });

            setPage(nextState.pageIndex);
            setPageSize(nextState.pageSize);
        },
    });

    return (
        <div className="space-y-6 mt-8">
            <div>
                <div className="flex items-center mb-4 space-x-4">
                    <div>
                        <h2 className="text-lg font-semibold text-slate-900">Device Metadata</h2>
                        <p className="text-sm text-slate-500">
                            Historical metadata and status information from this device
                        </p>
                    </div>
                    <div className="min-w-full sm:min-w-[200px]">
                        <TableDateSelect />
                    </div>
                </div>

                {error ? (
                    <div className="text-center text-red-500 py-4 bg-slate-50 rounded-lg border border-slate-200">
                        Error loading metadata. Please try again.
                    </div>
                ) : (
                    <PaginatedTable
                        table={table}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
}; 
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import TimelineView from '@/components/v2/timeline/TimelineView'
import { addDays } from 'date-fns'
interface DeviceTimelineProps {
  imei: string
}

const from = addDays(new Date(), -1)
const to = new Date()
export default function DeviceTimeline({ imei }: DeviceTimelineProps) {
  const { t } = useTranslation(['admin'])

  const [searchParams, setSearchParams] = useState({
    imei: imei || '',
    from,
    to,
    analyze: false,
    enabled: true
  })


  return (
    <div className="px-4">
      <div className="flex flex-row justify-between my-8">
        <h2 className="text-2xl">Device Stats last 24 hours</h2>
      </div>

      {/* <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            imei={imei || ''}
            onSelect={(val) => setImei(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <TableDateSelect />
        </div>
        <div className="flex gap-2 w-full sm:w-auto">
          <Button
            onClick={() => handleSearch(false)}
            disabled={!hasChanges}
            className="flex-1"
          >
            Search
          </Button>
          <Button
            onClick={() => handleSearch(true)}
            disabled={!hasChanges}
            variant="secondary"
            className={cn(
              "shrink-0",
              "bg-blue-50/80 hover:bg-blue-100/80",
              "text-blue-700 hover:text-blue-800",
              "border border-blue-200/60"
            )}
          >
            <Brain className="mr-2 h-4 w-4" />
            AI Analysis
          </Button>
        </div>
      </div> */}

      <TimelineView
        imei={searchParams.imei}
        from={searchParams.from}
        to={searchParams.to}
        analyze={searchParams.analyze}
        enabled={searchParams.enabled}
      />
    </div>
  )
} 
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Quote } from "../types";

interface SummaryCardProps {
    quote: Quote;
}
export default function SummaryCard({ quote }: SummaryCardProps) {
    return (
        <div className="flex-1 min-w-[300px]">
            <Card className="sticky top-6">
                <CardHeader className="pb-2">
                    <CardTitle className="text-lg font-bold">Quote Summary</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        <div>
                            <h3 className="text-sm font-medium text-slate-500">Total Cost</h3>
                            <p className="text-2xl font-bold">
                                € {quote.totals.totalCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                            <p className="text-sm text-slate-500">€ {quote.totals.costPerM2.toFixed(2)} per m²</p>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-slate-500">Total Sales</h3>
                            <p className="text-2xl font-bold">
                                € {quote.totals.totalSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                            <p className="text-sm text-slate-500">€ {quote.totals.salePerM2.toFixed(2)} per m²</p>
                        </div>

                        <div>
                            <h3 className="text-sm font-medium text-slate-500">Profit</h3>
                            <p className="text-2xl font-bold text-green-600">
                                € {quote.totals.profit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                            <p className="text-sm text-slate-500">Margin: {quote.totals.margin}%</p>
                        </div>

                        <div className="pt-4 border-t">
                            <h3 className="font-semibold mb-2">Project Details</h3>
                            <div className="text-sm space-y-1">
                                <div className="flex justify-between">
                                    <span className="text-slate-500">Dimensions:</span>
                                    <span>{quote.specifications.width}×{quote.specifications.length}m</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-slate-500">Area:</span>
                                    <span>{quote.specifications.area} m²</span>
                                </div>
                                {quote.specifications.hasWashboxes && (
                                    <div className="flex justify-between">
                                        <span className="text-slate-500">Washboxes:</span>
                                        <span>{quote.specifications.totalWashboxes} units</span>
                                    </div>
                                )}
                                <div className="flex justify-between">
                                    <span className="text-slate-500">Last Updated:</span>
                                    <span>{new Date(quote.updatedAt).toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
import { Button } from '@/components/ui/button';
import { useDeviceRpcs } from '../../hooks/useDeviceRpcs';
import { RpcList } from './RpcList';

interface RpcHistoryProps {
  deviceId: string;
}

export const RpcHistory = ({ deviceId }: RpcHistoryProps) => {
  const {
    rpcs,
    isLoadingRpcs,
    rpcsError,
    sendRpc,
    isSendingRpc,
  } = useDeviceRpcs(deviceId);

  if (isLoadingRpcs) {
    return <div>Loading rpcs...</div>;
  }

  if (rpcsError) {
    return <div>Error loading rpcs</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-end space-x-2">
        <Button
          onClick={() => sendRpc('reboot')}
          disabled={isSendingRpc}
        >
          Send Reboot Rpc
        </Button>
        <Button
          onClick={() => sendRpc('fetch_config')}
          disabled={isSendingRpc}
        >
          Send Fetch Config Rpc
        </Button>
      </div>

      <RpcList rpcs={rpcs} />
    </div>
  );
}; 
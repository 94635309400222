import { useDashboard, type DashboardDevice } from '../hooks/useDashboard';
import { DeviceCard } from '../components/DeviceCard';
import { DeviceTable } from '../components/DeviceTable';
import { useMemo, useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { LayoutGrid, Table2, AlertTriangle, ChevronDown, ChevronUp, Filter, X } from 'lucide-react';
import {
  errorStatuses,
  warningStatuses,
  operatingWellStatuses
} from '@/utils/statusHelpers';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";

type ViewMode = 'card' | 'table';

// Type for grouped devices by organization
type OrgGroupedDevices = Record<string, {
  organizationName: string;
  devices: DashboardDevice[];
  hasIssues: boolean;
}>;

export const AdminDevicesDashboard = () => {
  const { data: devices, isLoading, error } = useDashboard();
  const [viewMode, setViewMode] = useState<ViewMode>('card');
  const [sortByIssue, setSortByIssue] = useState(false);
  const [showIssuesSection, setShowIssuesSection] = useState(true);
  const [excludedDeviceIds, setExcludedDeviceIds] = useState<Record<string, boolean>>({});

  // Load saved view preference from localStorage on component mount
  useEffect(() => {
    const savedViewMode = localStorage.getItem('dashboard-view-mode');
    if (savedViewMode === 'card' || savedViewMode === 'table') {
      setViewMode(savedViewMode as ViewMode);
    }

    const savedSortByIssue = localStorage.getItem('dashboard-sort-by-issue');
    if (savedSortByIssue === 'true') {
      setSortByIssue(true);
    }

    const savedShowIssuesSection = localStorage.getItem('dashboard-show-issues-section');
    if (savedShowIssuesSection === 'false') {
      setShowIssuesSection(false);
    }

    // Load excluded device IDs from localStorage
    const savedExcludedDeviceIds = localStorage.getItem('dashboard-excluded-device-ids');
    if (savedExcludedDeviceIds) {
      try {
        setExcludedDeviceIds(JSON.parse(savedExcludedDeviceIds));
      } catch (e) {
        console.error('Failed to parse excluded device IDs from localStorage', e);
      }
    }
  }, []);

  // Save preferences to localStorage when they change
  useEffect(() => {
    localStorage.setItem('dashboard-view-mode', viewMode);
    localStorage.setItem('dashboard-sort-by-issue', sortByIssue.toString());
    localStorage.setItem('dashboard-show-issues-section', showIssuesSection.toString());
    localStorage.setItem('dashboard-excluded-device-ids', JSON.stringify(excludedDeviceIds));
  }, [viewMode, sortByIssue, showIssuesSection, excludedDeviceIds]);

  // Toggle a device to be excluded/included in the issues section
  const toggleDeviceExclusion = (deviceId: string | number) => {
    const id = deviceId.toString();
    setExcludedDeviceIds(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Clear all excluded devices
  const clearAllExcludedDevices = () => {
    setExcludedDeviceIds({});
  };

  // Get status priority for sorting (higher number = higher priority)
  const getStatusPriority = (status: string) => {
    // Check which category the status belongs to directly
    if (errorStatuses.includes(status as any)) {
      return 3;
    }

    if (warningStatuses.includes(status as any)) {
      return 2;
    }

    if (operatingWellStatuses.includes(status as any)) {
      return 1;
    }

    return 0; // Unknown status gets lowest priority
  };

  // Helper to check if a device has issues
  const deviceHasIssues = (device: DashboardDevice) => {
    return errorStatuses.includes(device.status as any) ||
      warningStatuses.includes(device.status as any);
  };

  // Sort devices by issue severity if sortByIssue is true
  const sortedDevices = useMemo(() => {
    if (!devices) return [];
    if (!sortByIssue) return devices;

    return [...devices].sort((a, b) => {
      const priorityA = getStatusPriority(a.status);
      const priorityB = getStatusPriority(b.status);

      // Sort by priority descending (highest first)
      if (priorityA !== priorityB) {
        return priorityB - priorityA;
      }

      // If same priority, sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  }, [devices, sortByIssue]);

  // Group devices by organization
  const devicesByOrg = useMemo(() => {
    if (!sortedDevices.length) return {};

    return sortedDevices.reduce((acc, device) => {
      const orgId = device.organizationId.toString();
      const hasIssue = deviceHasIssues(device);

      if (!acc[orgId]) {
        acc[orgId] = {
          organizationName: device.organizationName,
          devices: [],
          hasIssues: hasIssue
        };
      } else if (hasIssue && !acc[orgId].hasIssues) {
        // Mark organization as having issues if any device has issues
        acc[orgId].hasIssues = true;
      }

      acc[orgId].devices.push(device);
      return acc;
    }, {} as OrgGroupedDevices);
  }, [sortedDevices]);

  // Filter devices with issues by organization
  const issueDevices = useMemo(() => {
    if (!sortedDevices.length) return [];

    const result: DashboardDevice[] = [];

    sortedDevices.forEach(device => {
      // Only include device if it has issues AND is not excluded
      if (deviceHasIssues(device) && !excludedDeviceIds[device.id.toString()]) {
        result.push(device);
      }
    });

    return result;
  }, [sortedDevices, excludedDeviceIds]);

  // Count how many devices are excluded
  const excludedCount = useMemo(() => {
    return Object.values(excludedDeviceIds).filter(Boolean).length;
  }, [excludedDeviceIds]);

  // Get all devices with issues (including excluded ones) for the filter UI
  const allIssueDevices = useMemo(() => {
    if (!devices) return [];
    return devices.filter(deviceHasIssues).sort((a, b) => a.name.localeCompare(b.name));
  }, [devices]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading devices</div>;
  }

  // Render device cards or table based on view mode
  const renderDevices = (groupedDevices: OrgGroupedDevices) => {
    return (
      <div className="space-y-8">
        {Object.entries(groupedDevices).map(([orgId, { organizationName, devices }]) => (
          <div key={orgId} className="space-y-4">
            <h2 className="text-xl font-semibold">{organizationName}</h2>
            <div className="flex flex-wrap gap-4">
              {devices.map((device) => (
                <DeviceCard key={device.id} device={device} />
              ))}
            </div>
          </div>
        ))}

        {Object.keys(groupedDevices).length === 0 && (
          <div className="flex h-[calc(100vh-200px)] items-center justify-center rounded-lg border border-dashed p-8">
            <div className="text-center">
              <h3 className="text-lg font-medium">No devices found</h3>
              <p className="text-sm text-muted-foreground">Get started by creating a new device.</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-8 p-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Devices Overview</h1>
          <p className="text-muted-foreground">Manage and monitor all your devices</p>
        </div>

        <div className="flex items-center gap-2">
          {/* Sort by issue button */}
          <Button
            variant={sortByIssue ? "default" : "outline"}
            onClick={() => setSortByIssue(!sortByIssue)}
            className="flex items-center gap-1.5"
          >
            <AlertTriangle className="h-4 w-4" />
            {issueDevices.length > 0 && (
              <span className="bg-red-100 text-red-800 rounded-full px-1.5 py-0.5 text-xs font-semibold">
                {issueDevices.length}
              </span>
            )}
            <span>Sort by issues</span>
          </Button>
          {/* Exclude devices button */}
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="flex items-center gap-1.5"
              >
                <Filter className="h-4 w-4" />
                <span>Filter Devices</span>
                {excludedCount > 0 && (
                  <Badge variant="secondary" className="ml-1">
                    {excludedCount}
                  </Badge>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <div className="space-y-4">
                <div className="flex flex-col items-center justify-between">
                  <h4 className="font-medium">Exclude devices from issues</h4>
                  {excludedCount > 0 && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={clearAllExcludedDevices}
                      className="h-8 px-2 text-xs"
                    >
                      <X className="h-3 w-3 mr-1" />
                      Clear all ({excludedCount})
                    </Button>
                  )}
                </div>

                {allIssueDevices.length > 0 ? (
                  <ScrollArea className="h-[300px] pr-4">
                    <div className="space-y-2">
                      {allIssueDevices.map(device => (
                        <div key={device.id} className="flex items-center space-x-2">
                          <Checkbox
                            id={`exclude-device-${device.id}`}
                            checked={!!excludedDeviceIds[device.id.toString()]}
                            onCheckedChange={() => toggleDeviceExclusion(device.id)}
                          />
                          <label
                            htmlFor={`exclude-device-${device.id}`}
                            className="text-sm flex flex-col"
                          >
                            <span>{device.name}</span>
                            <span className="text-xs text-muted-foreground">{device.organizationName}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                ) : (
                  <p className="text-sm text-muted-foreground">No devices with issues available to filter.</p>
                )}
              </div>
            </PopoverContent>
          </Popover>

          {/* View mode toggle */}
          <Tabs value={viewMode} onValueChange={(value) => setViewMode(value as ViewMode)}>
            <TabsList>
              <TabsTrigger value="card" className="flex items-center gap-1.5">
                <LayoutGrid className="h-4 w-4" />
                <span>Cards</span>
              </TabsTrigger>
              <TabsTrigger value="table" className="flex items-center gap-1.5">
                <Table2 className="h-4 w-4" />
                <span>Table</span>
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* View content based on selected mode */}
      {viewMode === 'card' ? (
        // Card view with issues section first
        <div className="space-y-20">
          {/* Issues Section */}
          {issueDevices.length > 0 && (
            <div className="rounded-lg border border-red-200 bg-red-50 p-4">
              <div className="mb-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5 text-red-500" />
                  <h2 className="text-2xl font-bold text-red-700">Devices with Issues</h2>
                  <span className="bg-red-100 text-red-800 rounded-full px-2 py-1 text-sm font-semibold">
                    {issueDevices.length}
                  </span>
                  {excludedCount > 0 && (
                    <span className="text-sm text-red-500">
                      ({excludedCount} device{excludedCount !== 1 ? 's' : ''} filtered out)
                    </span>
                  )}
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowIssuesSection(!showIssuesSection)}
                  className="text-red-600 hover:text-red-800 hover:bg-red-100"
                >
                  {showIssuesSection ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                </Button>
              </div>

              {showIssuesSection ? (
                <div className="flex flex-wrap gap-4">
                  {issueDevices.map((device) => (
                    <DeviceCard key={device.id} device={device} />
                  ))}
                </div>
              ) : null}
            </div>
          )}

          {/* All Organizations Section */}
          <div>
            <h2 className="mb-6 text-2xl font-bold">All Organizations</h2>
            {renderDevices(devicesByOrg)}
          </div>
        </div>
      ) : (
        // Table view - keeping the original implementation for now
        <DeviceTable devices={sortedDevices} />
      )}
    </div>
  );
}; 
import clsx from "clsx"
import { NavLink } from "react-router-dom"

function SalesSidebar() {
  return (
    <div className="w-32 border-r min-h-screen p-4">
      <nav>
        <NavLink
          to="/sales/quotes"
          className={({ isActive }) =>
            clsx('block p-2', isActive && 'text-primary')
          }
        >
          Quotes
        </NavLink>
        <NavLink
          to="/sales/material-settings"
          className={({ isActive }) =>
            clsx('block p-2', isActive && 'text-primary')
          }
        >
          Material Settings
        </NavLink>
      </nav>
    </div>
  )
}

export default SalesSidebar
import { useMemo } from 'react';
import { useDeviceDataPoints } from '../../hooks/useDeviceDataPoints';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import PaginatedTable from '@/components/v2/PaginatedTable';
import TableDateSelect from '@/components/v2/selects/TableDateSelect';
import { DataPoint } from '../../hooks/useDeviceDataPoints';
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge';
import { Device } from '@/data/device';
import { NumberParam } from 'use-query-params';
import { withDefault } from 'use-query-params';
import { DateTimeParam, StringParam } from 'use-query-params';
import { useQueryParam } from 'use-query-params';

interface DeviceDataPointsProps {
    deviceId: string;
}

export const DeviceDataPoints = ({ deviceId }: DeviceDataPointsProps) => {
    const { t } = useTranslation(['admin'])
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))
    const [pageSize, setPageSize] = useQueryParam(
        'pageSize',
        withDefault(NumberParam, 20),
    )
    const [orgId, setOrgId] = useQueryParam('orgId', StringParam)
    const [from] = useQueryParam('from', DateTimeParam)
    const [to] = useQueryParam('to', DateTimeParam)

    const { data, isLoading, error } = useDeviceDataPoints(
        deviceId,
        pageSize,
        page,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined
    );

    const columns = useMemo<ColumnDef<DataPoint>[]>(() => {
        return [
            {
                accessorKey: 'timestamp',
                header: t('data_points.timestamp'),
                cell: ({ row }) => {
                    const { timestamp } = row.original

                    if (!timestamp) return null

                    return (
                        <span className="whitespace-nowrap">
                            {dateWithTimeInIsoFormat(new Date(timestamp))}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'waterLevelLeft',
                header: t('data_points.water_level_left'),
            },
            {
                accessorKey: 'waterLevelRight',
                header: t('data_points.water_level_right'),
            },
            {
                accessorKey: 'waterLevelPercentage',
                header: t('data_points.water_level_percentage'),
            },
            {
                accessorKey: 'fillValveState',
                header: t('data_points.fill_valve_state'),
            },
            {
                accessorKey: 'drainValveState',
                header: t('data_points.drain_valve_state'),
            },
            {
                accessorKey: 'dripLineValveState',
                header: t('data_points.drip_line_valve_state'),
            },
            {
                accessorKey: 'fertigationValveState',
                header: t('data_points.fertigation_valve_state'),
            },
            {
                accessorKey: 'balanceValveState',
                header: t('data_points.balance_valve_state'),
            },
            {
                accessorKey: 'activeSection',
                header: t('data_points.active_section'),
            },
            {
                accessorKey: 'autoRegulate',
                header: t('data_points.auto_regulate'),
            },
            {
                accessorKey: 'status',
                header: t('data_points.status'),
                cell: ({ row }) => {
                    const { status } = row.original

                    if (!status) return null

                    return (
                        <div className="max-w-fit h-4 flex items-center">
                            <DeviceStatusBadge status={status as Device['status']} />
                        </div>
                    )
                },
            },
            {
                accessorKey: 'tankTemperatureLeft',
                header: t('data_points.tank_temperature_left'),
            },
            {
                accessorKey: 'tankTemperatureRight',
                header: t('data_points.tank_temperature_right'),
            },
            {
                accessorKey: 'temperatureLeft',
                header: t('data_points.temperature_left'),
            },
            {
                accessorKey: 'temperatureRight',
                header: t('data_points.temperature_right'),
            },
            {
                accessorKey: 'moistureLeft',
                header: t('data_points.moisture_left'),
            },
            {
                accessorKey: 'moistureRight',
                header: t('data_points.moisture_right'),
            },
            {
                accessorKey: 'ecLeft',
                header: t('data_points.ec_left'),
            },
            {
                accessorKey: 'ecRight',
                header: t('data_points.ec_right'),
            },
            {
                accessorKey: 'varianceLeft',
                header: t('data_points.variance_left'),
            },
            {
                accessorKey: 'varianceRight',
                header: t('data_points.variance_right'),
            },
            {
                accessorKey: 'createdAt',
                header: t('organizations.createdAt.header'),
                cell: ({ row }) => {
                    const { createdAt } = row.original

                    if (!createdAt) return null

                    return (
                        <span className="whitespace-nowrap">
                            {dateWithTimeInIsoFormat(new Date(createdAt))}
                        </span>
                    )
                },
            },
        ]
    }, [])

    const table = useReactTable({
        data: data?.dataPoints || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        pageCount: data?.pageCount || 0,
        state: {
            pagination: {
                pageIndex: page,
                pageSize: pageSize,
            },
        },
        onPaginationChange: (updater) => {
            // make sure updater is callable (to avoid typescript warning)
            if (typeof updater !== 'function') return

            const nextState = updater({ pageIndex: page, pageSize: pageSize })

            setPage(nextState.pageIndex)
            setPageSize(nextState.pageSize)
        },
    })

    return (
        <div className="space-y-6 mt-8">
            <div>
                <div className="flex items-center mb-4 space-x-4">
                    <div>
                        <h2 className="text-lg font-semibold text-slate-900">Data Points</h2>
                        <p className="text-sm text-slate-500">
                            Historical data points collected from this device
                        </p>
                    </div>
                    <div className="min-w-full sm:min-w-[200px]">
                        <TableDateSelect />
                    </div>
                </div>

                {error ? (
                    <div className="text-center text-red-500 py-4 bg-slate-50 rounded-lg border border-slate-200">
                        Error loading data. Please try again.
                    </div>
                ) : (
                    <PaginatedTable
                        table={table}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
}; 
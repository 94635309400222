import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useEffect } from 'react'
import { Plus, Trash2 } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  failureToastClassNames,
  successfulToastClassNames,
} from '@/utils/toastHelpers'
import { Card, CardContent } from '@/components/ui/card'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import usePeripherals from '../hooks/usePeripherals'

const PeripheralReadingSchema = z.object({
  type: z.string().min(1, 'Type is required'),
  arrayIndex: z.number().min(0, 'Array index must be positive'),
})

const PeripheralSchema = z.object({
  type: z.enum(['sensor', 'actuator'] as const),
  status: z.enum(['testing', 'approved_for_production', 'deprecated'] as const),
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Description is required'),
  manufacturer: z.string().min(1, 'Manufacturer is required'),
  technicalSpecifications: z.string(),
  readings: z.array(PeripheralReadingSchema),
})

type FormData = z.infer<typeof PeripheralSchema>

interface PeripheralDialogProps {
  peripheralId?: string | null
  onClose: () => void
}

export function PeripheralDialog({ peripheralId, onClose }: PeripheralDialogProps) {
  const { toast } = useToast()
  const { createPeripheral, updatePeripheral, peripheral, isSingleLoading } = usePeripherals(
    null,
    undefined,
    undefined,
    peripheralId === 'new' ? null : peripheralId
  )

  const isOpen = Boolean(peripheralId)
  const isNew = peripheralId === 'new'

  const form = useForm<FormData>({
    resolver: zodResolver(PeripheralSchema),
    defaultValues: {
      type: 'sensor',
      status: 'testing',
      name: '',
      description: '',
      manufacturer: '',
      technicalSpecifications: '',
      readings: [],
    },
  })

  console.log(peripheral)

  useEffect(() => {
    if (peripheral && !isNew) {
      form.reset({
        type: peripheral.type,
        status: peripheral.status,
        name: peripheral.name,
        description: peripheral.description,
        manufacturer: peripheral.manufacturer,
        technicalSpecifications: peripheral.technicalSpecifications,
        readings: peripheral.readings,
      })
    }
  }, [peripheral, isNew, form])

  const handleSubmit = async (data: FormData) => {
    try {
      if (isNew) {
        await createPeripheral(data)
        toast({
          title: 'Peripheral created!',
          className: successfulToastClassNames,
        })
      } else if (peripheralId) {
        await updatePeripheral({ id: peripheralId, data })
        toast({
          title: 'Peripheral updated!',
          className: successfulToastClassNames,
        })
      }
      form.reset()
      onClose()
    } catch (err) {
      toast({
        title: `Failed to ${isNew ? 'create' : 'update'} peripheral`,
        className: failureToastClassNames,
      })
    }
  }

  const addReading = () => {
    const readings = form.getValues('readings')
    form.setValue('readings', [
      ...readings,
      { type: '', arrayIndex: readings.length },
    ])
  }

  const removeReading = (index: number) => {
    const readings = form.getValues('readings')
    const newReadings = readings.filter((_, i) => i !== index)
    // Update array indices for remaining readings
    const updatedReadings = newReadings.map((reading, i) => ({
      ...reading,
      arrayIndex: i,
    }))
    form.setValue('readings', updatedReadings)
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-h-[90%] max-w-3xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-3xl text-black">
            {isNew ? 'Create Peripheral' : 'Update Peripheral'}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="my-4 flex max-w-full flex-col gap-4"
          >
            {/* Peripheral Type Selection */}
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Peripheral Type</FormLabel>
                  <FormControl>
                    <Tabs
                      defaultValue={field.value}
                      className="w-full"
                      onValueChange={field.onChange}
                    >
                      <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger
                          value="sensor"
                          disabled={!isNew || isSingleLoading}
                        >
                          Sensor
                        </TabsTrigger>
                        <TabsTrigger
                          value="actuator"
                          disabled={!isNew || isSingleLoading}
                        >
                          Actuator
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Peripheral Status */}
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    disabled={isSingleLoading}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="testing">Testing</SelectItem>
                      <SelectItem value="approved_for_production">Approved for Production</SelectItem>
                      <SelectItem value="deprecated">Deprecated</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isSingleLoading} placeholder="e.g., Tryckcomponent" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isSingleLoading} placeholder="e.g., Digital pressure component with temperature measurement" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="manufacturer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Manufacturer</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isSingleLoading} placeholder="e.g., Nivakt" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="technicalSpecifications"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Technical Specifications</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isSingleLoading} placeholder="e.g., Output: RS485, Power: 5-30v" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Readings Section */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <FormLabel>Readings</FormLabel>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={addReading}
                  disabled={isSingleLoading}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Reading
                </Button>
              </div>

              <div className="space-y-4">
                {form.watch('readings').map((reading, index) => (
                  <Card key={index}>
                    <CardContent className="pt-4 flex items-end gap-4">
                      <FormField
                        control={form.control}
                        name={`readings.${index}.type`}
                        render={({ field }) => (
                          <FormItem className="flex-1">
                            <FormLabel>Type</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={isSingleLoading} placeholder="e.g., Pressure, Temperature" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name={`readings.${index}.arrayIndex`}
                        render={({ field }) => (
                          <FormItem className="w-24">
                            <FormLabel>Index</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                type="number"
                                disabled
                                value={index}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => removeReading(index)}
                        disabled={isSingleLoading}
                        className="text-destructive hover:text-destructive/90"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>

            <div className="mt-4 flex justify-end gap-4">
              <Button
                variant="outline"
                type="button"
                onClick={onClose}
                disabled={isSingleLoading}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                type="submit"
                className="font-semibold"
                isLoading={form.formState.isSubmitting || isSingleLoading}
              >
                {isNew ? 'Create' : 'Update'}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
} 
import { useNavigate } from 'react-router-dom';
import { type DashboardDevice } from '../hooks/useDashboard';
import { cn } from '@/lib/utils';
import { DeviceStatus } from '@/data/device';
import DeviceStatusBadge from '@/components/v2/DeviceStatusBadge';
import {
  errorStatuses,
  warningStatuses,
  mapGroupedStatusToBackgroundColorWithoutHover
} from '@/utils/statusHelpers';
import { localizedTimeSince } from '@/utils/dateHelpers';

interface DeviceCardProps {
  device: DashboardDevice;
}

export const DeviceCard = ({ device }: DeviceCardProps) => {
  const navigate = useNavigate();

  // Get status category using existing helpers
  const getStatusCategory = (status: string) => {
    if (errorStatuses.includes(status as any)) {
      return 'error';
    }

    if (warningStatuses.includes(status as any)) {
      return 'warning';
    }

    return 'good';
  };

  // Get background color class based on status category using existing helper
  const getBackgroundColor = () => {
    const category = getStatusCategory(device.status);

    // Use the existing helper function to get the background color
    if (category === 'error') {
      return mapGroupedStatusToBackgroundColorWithoutHover('error');
    }

    if (category === 'warning') {
      return mapGroupedStatusToBackgroundColorWithoutHover('warning');
    }

    return mapGroupedStatusToBackgroundColorWithoutHover('operating_well');
  };

  // Format the water level display with active section indicated by bold text
  const getWaterLevelDisplay = () => {
    const left = device.waterLevelLeft ? `${device.waterLevelLeft}mm` : 'Unknown';
    const right = device.waterLevelRight ? `${device.waterLevelRight}mm` : 'Unknown';
    if (device.activeSection === 'Left') {
      return <><b>{left}</b> | {right}</>;
    } else if (device.activeSection === 'Right') {
      return <>{left} | <b>{right}</b></>;
    } else {
      return `${left} | ${right}`;
    }
  };

  // Get firmware version
  const getFirmwareVersion = () => {
    return device.firmwareVersion || 'Unknown';
  };

  // Get formatted last data timestamp
  const getLastDataTimestamp = () => {
    if (!device.lastDataTimestamp) {
      return 'No data received';
    }

    const timestamp = new Date(device.lastDataTimestamp * 1000);
    return localizedTimeSince(timestamp);
  };

  // Get reference signal display
  const getReferenceSignalDisplay = () => {
    if (device.referenceSignal === null || device.referenceSignal === undefined) {
      return 'No data';
    }

    // Format the reference signal with 2 decimal places
    return `${device.referenceSignal}`;
  };

  // Get uptime display using a more precise formatter
  const getUptimeDisplay = () => {
    if (device.uptime === null || device.uptime === undefined) {
      return 'Unknown';
    }
    
    // Format uptime (in seconds) to show as days, hours, minutes
    const days = Math.floor(device.uptime / 86400);
    const hours = Math.floor((device.uptime % 86400) / 3600);
    const minutes = Math.floor((device.uptime % 3600) / 60);
    
    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `<1m`;
    }
  };

  // Get reset reason display
  const getResetReasonDisplay = () => {
    if (!device.resetReason) {
      return 'Unknown';
    }
    
    return device.resetReason;
  };

  // Get auto regulate state display
  const getAutoRegulateStateDisplay = () => {
    if (device.autoRegulateState === null || device.autoRegulateState === undefined) {
      return 'Unknown';
    }

    return device.autoRegulateState === 'on' ? 'On' : device.autoRegulateState === 'off' ? 'Off' : 'Unknown';
  };

  // Get fill valve state display
  const getFillValveStateDisplay = () => {
    if (device.fillValveState === null || device.fillValveState === undefined) {
      return 'Unknown';
    }

    return device.fillValveState > 0 ? 'On' : 'Off';
  };
  return (
    <div
      className={cn(
        'p-3 rounded-2xl cursor-pointer relative min-h-[170px] flex flex-col',
        getBackgroundColor(),
        // Add smooth transition and hover effects
        'transition-all duration-200 ease-in-out',
        'hover:shadow-lg hover:scale-[1.02] hover:brightness-[1.03]'
      )}
      onClick={() => navigate(`/admin/v3/org/${device.organizationId}/devices/${device.id}`)}
    >
      {/* Header with type and name */}
      <div className="flex items-center justify-between mb-1">
        <div className="flex items-center gap-1">
          <div>
            <div className="text-xs text-gray-600">{device.organizationName}</div>
            <div className="text-sm font-semibold">{device.name}</div>
          </div>
        </div>
        <DeviceStatusBadge status={device.status as DeviceStatus} />
      </div>

      {/* Divider */}
      <hr className="my-1 border-gray-200" />

      {/* Content - Using a grid layout for more compact display */}
      <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-xs">
        {/* Water Levels in mm */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M3 20h18M5 20v-4m7 4v-8m7 8v-12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Water Levels</span>
        </div>
        <span className="text-right text-gray-500">{getWaterLevelDisplay()}</span>

        {/* Auto Regulate State */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Auto Regulate</span>
        </div>
        <span className="text-right text-gray-500">{getAutoRegulateStateDisplay()}</span>

        {/* Fill Valve State */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Fill Valve</span>
        </div>
        <span className="text-right text-gray-500">{getFillValveStateDisplay()}</span>

        {/* Firmware Version */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M9 3H5a2 2 0 00-2 2v4m6-6h10a2 2 0 012 2v4M3 9v10a2 2 0 002 2h4m-6-6h6m-6 0v-6m16 0H9m14 0v6m0 0v4a2 2 0 01-2 2h-4m6-6h-6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Firmware</span>
        </div>
        <span className="text-right text-gray-500">{getFirmwareVersion()}</span>

        {/* Reference Signal */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M13 10V3L4 14h7v7l9-11h-7z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Reference Signal</span>
        </div>
        <span className="text-right text-gray-500">{getReferenceSignalDisplay()}</span>

        {/* Uptime */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Uptime</span>
        </div>
        <span className="text-right text-gray-500">{getUptimeDisplay()}</span>

        {/* Reset Reason */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Reset Reason</span>
        </div>
        <span className="text-right text-gray-500">{getResetReasonDisplay()}</span>

        {/* Last Data Timestamp */}
        <div className="flex items-center gap-1">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-gray-600">
            <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span className="text-gray-600">Last Data</span>
        </div>
        <span className="text-right text-gray-500">{getLastDataTimestamp()}</span>
      </div>
    </div>
  );
}; 
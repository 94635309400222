import { RefreshCw } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import type { PaginationState } from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import usePeripherals from './hooks/usePeripherals'
import { PeripheralTable } from './components/PeripheralTable'
import { PeripheralDialog } from './components/PeripheralDialog'

export default function PeripheralsPage() {
  const [searchText, setSearchText] = useQueryParam('q', StringParam)
  const [peripheralId, setPeripheralId] = useQueryParam('peripheralId', StringParam)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
  } = usePeripherals(
    searchText,
    pagination.pageSize,
    pagination.pageIndex,
  )

  return (
    <div>
      <div className="relative my-8">
        <h2 className="text-3xl">Peripheral Types</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={async () => refetch()}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="pl-2 text-base">Refresh</span>
          </Button>
        </div>
      </div>
      <div className="my-4 flex gap-4">
        <Input
          className="max-w-xs"
          type="text"
          placeholder="Search component types"
          defaultValue={searchText || ''}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="flex flex-1 justify-end">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() => setPeripheralId('new')}
          >
            +
          </Button>
        </div>
      </div>
      <div>
        <PeripheralTable
          data={data?.items ?? []}
          total={data?.total ?? 0}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          onEdit={(id) => setPeripheralId(id.toString())}
        />
        {peripheralId ? (
          <PeripheralDialog
            peripheralId={peripheralId}
            onClose={() => setPeripheralId(undefined)}
          />
        ) : null}
      </div>
    </div>
  )
} 
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { axiosClient } from '@/lib/axios'
import * as Sentry from '@sentry/react'

// Types
export type PeripheralType = 'sensor' | 'actuator'
export type PeripheralStatus = 'testing' | 'approved_for_production' | 'deprecated'

export type PeripheralReading = {
  type: string
  arrayIndex: number
}

export interface Peripheral {
  id: number
  name: string
  description: string
  manufacturer: string
  technicalSpecifications: string
  type: PeripheralType
  status: PeripheralStatus
  readings: PeripheralReading[]
  createdAt: string
  updatedAt: string | null
}

export interface PaginatedPeripherals {
  items: Peripheral[]
  total: number
}

// Type for create/update operations
export type PeripheralInput = Omit<Peripheral, 'id' | 'createdAt' | 'updatedAt'>

// API functions
const getPeripheral = async (id: string): Promise<Peripheral> => {
  try {
    const res = await axiosClient.get(`/admin/peripherals/${id}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

const getPeripherals = async (
  signal: AbortSignal,
  searchText?: string | null,
  take?: number,
  page?: number,
): Promise<PaginatedPeripherals> => {
  try {
    const params = new URLSearchParams()
    if (take) params.append('take', take.toString())
    if (page) params.append('page', page.toString())
    if (searchText) params.append('q', searchText)

    const res = await axiosClient.get(`/admin/peripherals?${params.toString()}`, {
      signal,
    })
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { searchText, take, page } })
    throw err
  }
}

const createPeripheral = async (data: PeripheralInput) => {
  try {
    const res = await axiosClient.post('/admin/peripherals', data)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { data } })
    throw err
  }
}

const updatePeripheral = async (id: string, data: PeripheralInput) => {
  try {
    const res = await axiosClient.put(`/admin/peripherals/${id}`, data)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id, data } })
    throw err
  }
}

const deletePeripheral = async (id: string) => {
  try {
    const res = await axiosClient.delete(`/admin/peripherals/${id}`)
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { id } })
    throw err
  }
}

export function usePeripherals(
  searchText?: string | null,
  take?: number,
  page?: number,
  id?: string | null,
) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const queryKey = ['peripherals', { take, page, searchText }]
  const singleQueryKey = ['peripheral', id]

  const {
    data: peripheral,
    isLoading: isSingleLoading
  } = useQuery({
    queryKey: singleQueryKey,
    queryFn: () => getPeripheral(id!),
    enabled: Boolean(id) && id !== 'new',
  })

  const {
    data,
    isLoading,
    refetch,
    isRefetching
  } = useQuery({
    queryKey,
    queryFn: async () => getPeripherals(
      abortController.signal,
      searchText,
      take,
      page
    ),
    placeholderData: keepPreviousData,
  })

  const createMutation = useMutation({
    mutationFn: createPeripheral,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['peripherals'],
        exact: false
      })
    },
  })

  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: PeripheralInput }) =>
      updatePeripheral(id, data),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['peripherals'],
        exact: false
      })
      queryClient.refetchQueries({
        queryKey: ['peripheral'],
        exact: false
      })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deletePeripheral,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['peripherals'],
        exact: false
      })
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return {
    data,
    peripheral,
    isLoading,
    isSingleLoading,
    refetch,
    isRefetching,
    createPeripheral: createMutation.mutateAsync,
    updatePeripheral: updateMutation.mutateAsync,
    deletePeripheral: deleteMutation.mutateAsync
  }
}

export default usePeripherals 
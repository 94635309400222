import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';
import { PeripheralFormValues } from '../components/device-peripherals/PeripheralDialog';
import { Peripheral } from '../../peripherals/hooks/usePeripherals';
import { Section } from '@/admin-v3/models/types';

const BASE_URL = '/admin/device-peripherals';

export type DevicePeripheral = {
  id: number;
  deviceId: number;
  name: string;
  address: number;
  section: Section;
  offset: number;
  peripheral: Peripheral | null;
  createdAt: string;
  updatedAt: string;
};

type DevicePeripheralResponse = {
  items: DevicePeripheral[];
  total: number;
};

export const useDevicePeripherals = (deviceId: string) => {
  const queryClient = useQueryClient();

  const {
    data: peripheralsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['device', deviceId, 'peripherals'],
    queryFn: async ({ signal }) => {
      try {
        const response = await axiosClient.get<DevicePeripheralResponse>(BASE_URL, {
          params: { deviceId },
          signal,
        });
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
  });

  const { mutate: createPeripheral, isPending: isCreating } = useMutation({
    mutationFn: async (data: PeripheralFormValues & { deviceId: string }) => {
      try {
        const response = await axiosClient.post(BASE_URL, data);
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['device', deviceId, 'peripherals'] });
    },
  });

  const { mutate: updatePeripheral, isPending: isUpdating } = useMutation({
    mutationFn: async (data: PeripheralFormValues & { id: number }) => {
      try {
        const response = await axiosClient.put(`${BASE_URL}/${data.id}`, {
          name: data.name,
          address: data.address,
          section: data.section,
          peripheralId: data.peripheralId,
        });
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['device', deviceId, 'peripherals'] });
    },
  });

  const { mutate: deletePeripheral, isPending: isDeleting } = useMutation({
    mutationFn: async (id: number) => {
      try {
        await axiosClient.delete(`${BASE_URL}/${id}`);
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['device', deviceId, 'peripherals'] });
    },
  });

  return {
    peripherals: peripheralsData?.items ?? [],
    totalPeripherals: peripheralsData?.total ?? 0,
    isLoading,
    error,
    createPeripheral,
    isCreating,
    updatePeripheral,
    isUpdating,
    deletePeripheral,
    isDeleting,
  };
};

export const useAvailableDevicePeripherals = (deviceId: string) => {
  return useQuery({
    queryKey: ['device-peripherals', deviceId],
    queryFn: async ({ signal }) => {
      try {
        const response = await axiosClient.get<DevicePeripheralResponse>(BASE_URL, {
          params: { deviceId },
          signal,
        });
        return response.data;
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    },
  });
}; 
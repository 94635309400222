import { Hourglass } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ActiveSectionIcon from '@/assets/icons/activesection.svg?react'
import DropIcon from '@/assets/icons/drop.svg?react'
import { useToast } from '@/components/ui/use-toast'
import type {
  BooleanState,
  DetailedDevice,
  LeftRightState,
  OpenCloseValve,
  ValveState,
  ValveType,
} from '@/data/device'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'
import { failureToastClassNames } from '@/utils/toastHelpers'

import { Card, type CardProps } from '../ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Switch } from '../ui/switch'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip'

import WaterValveActionPopover from './dialogs/WaterValveActionPopover'
import DashboardValveIcon from './icon-resolvers/ValveStateIcon'
import DashboardWaterLevelIcon from './icon-resolvers/WaterLevelIcon'

type InteractiveValveState = {
  isPending: boolean
  desiredState: ValveState
  currentState: ValveState
}

const CYCLE_TIMES = [
  { value: 1800, label: '30 minutes' },
  { value: 3600, label: '1 hour' },
  { value: 7200, label: '2 hours' },
  { value: 10800, label: '3 hours' },
  { value: 21600, label: '6 hours' },
  { value: 28800, label: '8 hours' },
  { value: 36000, label: '10 hours' },
  { value: 43200, label: '12 hours' },
  { value: 86400, label: '24 hours' },
]

// Reset timer if valve has not been successfully changed for 60s
const delay = 60000

function DetailedDeviceCard({
  device,
  cardColor,
  onOpenCloseValve,
  onEnableDisableAutoRegulate,
  onUpdateCycleTime,
  onUpdateActiveSection,
  showWriteFunctionality,
}: {
  device: DetailedDevice
  cardColor?: CardProps['variant']
  onOpenCloseValve?: (data: OpenCloseValve) => Promise<void>
  onEnableDisableAutoRegulate?: (regulateState: boolean) => Promise<void>
  onUpdateCycleTime?: (seconds: number) => Promise<void>
  onUpdateActiveSection?: (section: number) => Promise<void>
  showWriteFunctionality: boolean
}) {
  const { t } = useTranslation('app')
  const [valveStates, setValveStates] = useState<
    Record<ValveType, InteractiveValveState>
  >({
    fill: {
      isPending: false,
      desiredState: device.data?.fillValveState || 'unknown',
      currentState: device.data?.fillValveState || 'unknown',
    },
    drain: {
      isPending: false,
      desiredState: device.data?.drainValveState || 'unknown',
      currentState: device.data?.drainValveState || 'unknown',
    },
    dripLine: {
      isPending: false,
      desiredState: device.data?.dripLineValveState || 'unknown',
      currentState: device.data?.dripLineValveState || 'unknown',
    },
  })

  const { toast } = useToast()

  const sections = [
    {
      value: 'left',
      label: device.leftSectionAlias || t(`device_card.active_section_left`),
    },
    {
      value: 'right',
      label: device.rightSectionAlias || t(`device_card.active_section_right`),
    },
  ]

  useEffect(() => {
    const timers: Record<string, NodeJS.Timeout> = {}
    const keys = Object.keys(valveStates)
    for (const key of keys) {
      if (valveStates[key as ValveType].isPending) {
        timers[key] = setTimeout(
          () =>
            setValveStates((prev) => ({
              ...prev,
              [key]: { ...prev[key as ValveType], isPending: false },
            })),
          delay,
        )
      }
    }

    return () => {
      Object.values(timers).forEach((timer) => clearTimeout(timer))
    }
  }, [valveStates])

  const handleAutoRegulateChange = (regulateState: BooleanState) => {
    if (onEnableDisableAutoRegulate && regulateState !== 'unknown') {
      onEnableDisableAutoRegulate(regulateState === 'on' ? true : false)
    }
  }

  const handleCycleTimeChange = (seconds: number) => {
    if (onUpdateCycleTime) {
      onUpdateCycleTime(seconds)
    }
  }

  const handleActiveSectionChange = (section: LeftRightState) => {
    if (onUpdateActiveSection && section !== 'unknown') {
      onUpdateActiveSection(section === 'left' ? 0 : 1)
    }
  }

  const handleOpenCloseValve = async (data: OpenCloseValve) => {
    try {
      if (!onOpenCloseValve) {
        toast({
          title: `Could not ${data.action} valve`,
          className: failureToastClassNames,
        })
        return
      }

      await onOpenCloseValve(data)
      setValveStates((prev) => ({
        ...prev,
        [data.type]: {
          ...prev[data.type],
          isPending: true,
          desiredState: data.action === 'open' ? 'open' : 'closed',
        },
      }))
    } catch (err) {
      toast({
        title: `Failed to ${data.action} the valve`,
        description:
          'Try again in a few minutes. Contact support if the problem keeps occuring',
        className: failureToastClassNames,
      })
    }
  }

  return (
    <Card
      variant={cardColor}
      className={`flex h-full w-full flex-col justify-between gap-2 p-4`}
    >
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col justify-between gap-4">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <DashboardWaterLevelIcon
                  hasWaterLevelError={device.status === 'low_water'}
                  waterLevelLeft={device.data?.waterLevelLeft}
                  waterLevelRight={device.data?.waterLevelRight}
                />
                <span className="whitespace-nowrap text-sm">
                  {t('device_card.volume')}
                </span>
              </div>
              <span
                className={`text-normal font-semibold ${
                  device.status === 'low_water' ? 'text-rockRed' : ''
                }`}
              >
                {device.data?.waterLevelPercentage ? (
                  `${Number(device.data?.waterLevelPercentage).toFixed(2)}%`
                ) : (
                  <TooltipProvider delayDuration={0} disableHoverableContent>
                    <Tooltip>
                      <TooltipTrigger>
                        <span className="text-gray-500 opacity-80">
                          {capitalizeFirstCharacter(t(`device_card.unknown`))}
                        </span>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('device_card.data_unknown_tooltip')}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </span>
            </div>

            {/* Fill valve */}
            <div className="flex h-10 items-center justify-between">
              <div className="flex items-center gap-2">
                <DashboardValveIcon valveState={device.data?.fillValveState} />
                <span className="min-w-[100px] text-sm">
                  {t('device_card.fill_valve')}
                </span>
                <div className="text-sm font-semibold">
                  {valveStates['fill'].isPending ? (
                    <div className="flex items-baseline gap-1">
                      {valveStates['fill'].desiredState === 'open'
                        ? capitalizeFirstCharacter(
                            t(`device_card.valve_opening`),
                          )
                        : capitalizeFirstCharacter(
                            t(`device_card.valve_closing`),
                          )}
                      <div className="loading text-xl leading-4"></div>
                    </div>
                  ) : device.data?.fillValveState ? (
                    capitalizeFirstCharacter(
                      t(`device_card.valve_${device.data.fillValveState}`),
                    )
                  ) : (
                    <TooltipProvider delayDuration={0} disableHoverableContent>
                      <Tooltip>
                        <TooltipTrigger>
                          <span className="text-gray-500 opacity-80">
                            {capitalizeFirstCharacter(t(`device_card.unknown`))}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          {t('device_card.valve_unknown_tooltip')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </div>
              <WaterValveActionPopover
                device={device}
                showPPMInput={true}
                currentState={device.data?.fillValveState || 'unknown'}
                onOpenCloseValve={async (data) =>
                  handleOpenCloseValve({ ...data, type: 'fill' })
                }
                disabled={
                  !device.data?.fillValveState || !showWriteFunctionality
                }
              />
            </div>

            {/* Drip line valves. Only present for certain equestrian sand arenas */}
            {device.type === 'equestrian_sand' &&
            device.usesDripLines === true ? (
              <div className="flex h-10 items-center justify-between">
                <div className="flex items-center gap-2">
                  <DashboardValveIcon
                    valveState={device.data?.dripLineValveState}
                  />
                  <span className="min-w-[100px] text-sm">
                    {t('device_card.drip_line_valve')}
                  </span>
                  <div className="text-sm font-semibold">
                    {valveStates['dripLine'].isPending ? (
                      <div className="flex items-baseline gap-1">
                        {valveStates['dripLine'].desiredState === 'open'
                          ? capitalizeFirstCharacter(
                              t(`device_card.valve_opening`),
                            )
                          : capitalizeFirstCharacter(
                              t(`device_card.valve_closing`),
                            )}
                        <div className="loading text-xl leading-4"></div>
                      </div>
                    ) : device.data?.dripLineValveState ? (
                      capitalizeFirstCharacter(
                        t(
                          `device_card.valve_${device.data.dripLineValveState}`,
                        ),
                      )
                    ) : (
                      <TooltipProvider
                        delayDuration={0}
                        disableHoverableContent
                      >
                        <Tooltip>
                          <TooltipTrigger>
                            <span className="text-gray-500 opacity-80">
                              {capitalizeFirstCharacter(
                                t(`device_card.unknown`),
                              )}
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('device_card.valve_unknown_tooltip')}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                </div>
                <WaterValveActionPopover
                  device={device}
                  currentState={device.data?.dripLineValveState || 'unknown'}
                  onOpenCloseValve={async (data) =>
                    handleOpenCloseValve({ ...data, type: 'dripLine' })
                  }
                  disabled={
                    !device.data?.dripLineValveState || !showWriteFunctionality
                  }
                />
              </div>
            ) : null}

            {/* Drain valve */}
            {/* <div className="flex h-10 items-center justify-between">
              <div className="flex items-center gap-2">
                <DashboardValveIcon valveState={device.data?.drainValveState} />
                <span className="min-w-[100px] text-sm">
                  {t('device_card.drain_valve')}
                </span>
                <div className="text-sm font-semibold">
                  {valveStates['drain'].isPending ? (
                    <div className="flex items-baseline gap-1">
                      {valveStates['drain'].desiredState === 'open'
                        ? capitalizeFirstCharacter(
                            t(`device_card.valve_opening`),
                          )
                        : capitalizeFirstCharacter(
                            t(`device_card.valve_closing`),
                          )}
                      <div className="loading text-xl leading-4"></div>
                    </div>
                  ) : device.data?.drainValveState ? (
                    capitalizeFirstCharacter(
                      t(`device_card.valve_${device.data.drainValveState}`),
                    )
                  ) : (
                    <TooltipProvider delayDuration={0} disableHoverableContent>
                      <Tooltip>
                        <TooltipTrigger>
                          <span className="text-gray-500 opacity-80">
                            {capitalizeFirstCharacter(t(`device_card.unknown`))}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          {t('device_card.valve_unknown_tooltip')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </div>
              <WaterValveActionPopover
                device={device}
                currentState={device.data?.drainValveState || 'unknown'}
                onOpenCloseValve={handleOpenCloseDrainValve}
                disabled={
                  !device.data?.drainValveState || !showWriteFunctionality
                }
              />
            </div> */}

            {/* Auto regulate */}
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <DropIcon />
                <span className="min-w-[100px] whitespace-nowrap text-sm">
                  {t('device_card.auto_regulate')}
                </span>
              </div>
              <div>
                <Switch
                  disabled={!showWriteFunctionality}
                  checked={
                    device.data?.autoRegulate?.toString() === 'on'
                      ? true
                      : false
                  }
                  onCheckedChange={(val) =>
                    handleAutoRegulateChange(val ? 'on' : 'off')
                  }
                />
              </div>
            </div>

            {/* Active section */}
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <ActiveSectionIcon />
                <span className="whitespace-nowrap text-sm">
                  {t('device_card.active_section')}
                </span>
              </div>
              <div>
                <Select
                  disabled={!showWriteFunctionality}
                  onValueChange={(val) =>
                    handleActiveSectionChange(val as LeftRightState)
                  }
                  value={device.data?.activeSection?.toString() || ''}
                >
                  <SelectTrigger className="w-[150px] truncate sm:w-[180px]">
                    <SelectValue placeholder={t('device_card.unknown')} />
                  </SelectTrigger>
                  <SelectContent>
                    {sections.map((s) => (
                      <SelectItem key={s.value} value={s.value.toString()}>
                        {s.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Cycle time */}
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Hourglass />
                <span className="whitespace-nowrap text-sm">
                  {t('device_card.cycle_time')}
                </span>
              </div>
              <div className="w-[150px] sm:w-[180px]">
                <Select
                  disabled={!showWriteFunctionality}
                  onValueChange={(val) => handleCycleTimeChange(Number(val))}
                  value={device?.data?.cycleTime?.toString() || ''}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t('device_card.unknown')} />
                  </SelectTrigger>
                  <SelectContent>
                    {CYCLE_TIMES.map((ct) => (
                      <SelectItem key={ct.value} value={ct.value.toString()}>
                        {ct.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
export default DetailedDeviceCard

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/components/ui/use-toast';
import { useSetMapping, type DeviceMapping, type PeripheralFunction } from '../../hooks/useDeviceMapping';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Section } from '@/admin-v3/models/types';
import { useDevicePeripherals } from '../../hooks/useDevicePeripherals';

const formSchema = z.object({
  devicePeripheralId: z.string(),
  index: z.number(),
});

type MappingDialogProps = {
  deviceId: number;
  function: PeripheralFunction;
  section: Section;
  mapping: DeviceMapping | null | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export function MappingDialog({ deviceId, function: peripheralFunction, section, mapping, isOpen, onClose }: MappingDialogProps) {
  const { peripherals, isLoading: isPeripheralsLoading } = useDevicePeripherals(deviceId.toString());
  const { toast } = useToast();
  const setMapping = useSetMapping();

  // Filter peripherals by section
  const sectionPeripherals = peripherals?.filter(s => s.section === section);

  const existingMapping = mapping?.peripheralMappings.find(
    sm => sm.function === peripheralFunction && sm.section === section
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      devicePeripheralId: existingMapping?.devicePeripheralId.toString() || '',
      index: existingMapping?.index || 0,
    },
  });

  const selectedPeripheralId = form.watch('devicePeripheralId');
  const selectedPeripheral = sectionPeripherals?.find(s => s.id.toString() === selectedPeripheralId);

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    setMapping.mutate({
      deviceId,
      devicePeripheralId: Number(data.devicePeripheralId),
      function: peripheralFunction,
      section,
      index: data.index,
    }, {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Mapping updated successfully'
        });
        form.reset();
        onClose();
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update mapping',
          variant: 'destructive'
        });
      },
    });
  };

  const title = `${section.charAt(0).toUpperCase() + section.slice(1)} ${peripheralFunction.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Map {title}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="devicePeripheralId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Select Peripheral</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                    disabled={isPeripheralsLoading}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a peripheral" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {sectionPeripherals?.length === 0 && (
                        <div className="p-2 text-sm text-muted-foreground text-center">
                          No peripherals available for {section} section.
                          <Button 
                            variant="link" 
                            className="h-auto p-0 text-sm"
                            onClick={() => {
                              onClose();
                              // TODO: Navigate to peripheral management
                            }}
                          >
                            Add a peripheral
                          </Button>
                        </div>
                      )}
                      {sectionPeripherals?.map((peripheral) => (
                        <SelectItem key={peripheral.id} value={peripheral.id.toString()}>
                          {peripheral.name} ({peripheral.address})
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedPeripheral?.peripheral && (
              <Card className="mt-2">
                <CardContent className="pt-4 text-sm space-y-4">
                  {selectedPeripheral.peripheral.description && (
                    <div>
                      <span className="text-muted-foreground">Description:</span>
                      <span className="ml-2">{selectedPeripheral.peripheral.description}</span>
                    </div>
                  )}
                  {selectedPeripheral.peripheral.technicalSpecifications && (
                    <div>
                      <span className="text-muted-foreground">Technical Specifications:</span>
                      <span className="ml-2">{selectedPeripheral.peripheral.technicalSpecifications}</span>
                    </div>
                  )}
                  {selectedPeripheral.peripheral.readings.length > 0 && (
                    <div>
                      <span className="text-muted-foreground">Available Readings:</span>
                      <div className="mt-1 flex flex-wrap gap-1">
                        {selectedPeripheral.peripheral.readings.map((reading, i) => (
                          <Badge key={i} variant="secondary">
                            {reading.type} (Index: {reading.arrayIndex})
                          </Badge>
                        ))}
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}

            <FormField
              control={form.control}
              name="index"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reading Index</FormLabel>
                  <Select 
                    onValueChange={(value) => field.onChange(Number(value))} 
                    value={field.value.toString()}
                    disabled={!selectedPeripheral?.peripheral?.readings.length}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select reading index" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {selectedPeripheral?.peripheral?.readings.map((reading) => (
                        <SelectItem key={reading.arrayIndex} value={reading.arrayIndex.toString()}>
                          {reading.type} (Index: {reading.arrayIndex})
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end space-x-2">
              <Button 
                type="submit" 
                disabled={
                  setMapping.isPending || 
                  isPeripheralsLoading || 
                  !selectedPeripheral?.peripheral?.readings.length
                }
              >
                {setMapping.isPending ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
} 
import { Check, RefreshCw, AlertCircle } from 'lucide-react';

interface SyncStatusIndicatorProps {
  status: 'idle' | 'syncing' | 'success' | 'error';
  lastSynced: string | null;
}

export function SyncStatusIndicator({ status, lastSynced }: SyncStatusIndicatorProps) {
  return (
    <div className="flex items-center text-xs text-gray-500">
      {status === 'syncing' && (
        <>
          <RefreshCw className="h-3 w-3 mr-1 animate-spin" />
          <span>Saving...</span>
        </>
      )}
      
      {status === 'success' && (
        <>
          <Check className="h-3 w-3 mr-1 text-green-500" />
          <span>Saved</span>
        </>
      )}
      
      {status === 'error' && (
        <>
          <AlertCircle className="h-3 w-3 mr-1 text-red-500" />
          <span>Failed to save</span>
        </>
      )}
      
      {status === 'idle' && lastSynced && (
        <span>Last saved: {new Date(lastSynced).toLocaleTimeString()}</span>
      )}
    </div>
  );
} 
import {
  LineItem,
  Quote,
  QuoteSpecifications,
  RawSpecification,
  RawLineItem,
} from '../types';
import {
  calculateLineItemValues,
  calculateSpecificationValues,
  calculateTotals,
  recalculateMaterialQuantities
} from '../services/materialCalculator';
import { generateSampleQuote } from '../services/materialGenerator';
import {
  loadQuotesFromStorage,
  saveQuoteToStorage,
} from '../services/storageService';

// In-memory cache of quotes
let quotes: Quote[] = [];

// Initialize with a sample quote if empty
const initializeStore = async () => {
  // Load quotes from localStorage
  const storedQuotes = await loadQuotesFromStorage();
  
  // If no quotes in storage, create a sample one
  if (storedQuotes.length === 0) {
    const sampleQuote = generateSampleQuote();
    await saveQuoteToStorage(sampleQuote);
    quotes = [sampleQuote];
  } else {
    quotes = storedQuotes;
  }
};

// Call initialize function
initializeStore();

const updateMaterialQuantities = (materials: LineItem[], specs: QuoteSpecifications): LineItem[] => {
  return recalculateMaterialQuantities(materials, specs);
};

export const getQuotes = async (): Promise<Quote[]> => {
  // Refresh quotes from storage to ensure we have the latest
  quotes = await loadQuotesFromStorage();
  return [...quotes];
};

export const getQuote = async (id?: string): Promise<Quote> => {
  // Refresh quotes from storage
  quotes = await loadQuotesFromStorage();
  
  if (!id && quotes.length > 0) {
    return { ...quotes[0] };
  }

  const quote = quotes.find(q => q.id === id);
  if (!quote) {
    throw new Error(`Quote with ID ${id} not found`);
  }

  return { ...quote };
};

export const initiateQuote = async (): Promise<Quote> => {
  const newQuote = generateSampleQuote();
  quotes.push(newQuote);
  
  // Save to storage
  await saveQuoteToStorage(newQuote);
  
  return newQuote;
};

export const updateSpecifications = async (id: string, rawSpecs: Partial<RawSpecification>): Promise<Quote> => {
  const quoteIndex = quotes.findIndex(q => q.id === id);
  if (quoteIndex === -1) {
    throw new Error(`Quote with ID ${id} not found`);
  }

  const quote = quotes[quoteIndex];

  // Create updated raw specs by merging current with changes
  const updatedRawSpecs: RawSpecification = {
    ...quote.specifications,
    ...rawSpecs
  };

  // Calculate derived values
  const calculatedValues = calculateSpecificationValues(updatedRawSpecs);

  // Create complete specifications
  const fullSpecs: QuoteSpecifications = {
    ...updatedRawSpecs,
    ...calculatedValues
  };

  // Update material quantities based on new specifications
  const updatedMaterials = updateMaterialQuantities(quotes[quoteIndex].materials, fullSpecs);

  // Calculate new totals
  const updatedTotals = calculateTotals(updatedMaterials, fullSpecs.area);

  // Update the quote
  const updatedQuote: Quote = {
    ...quotes[quoteIndex],
    specifications: fullSpecs,
    materials: updatedMaterials,
    totals: updatedTotals,
    updatedAt: new Date().toISOString()
  };

  quotes[quoteIndex] = updatedQuote;
  
  // No need to save here as the useQuote hook will handle saving

  return { ...updatedQuote };
};

export const updateMaterials = async (id: string, rawMaterials: RawLineItem[]): Promise<Quote> => {
  const quoteIndex = quotes.findIndex(q => q.id === id);
  if (quoteIndex === -1) {
    throw new Error(`Quote with ID ${id} not found`);
  }

  // Calculate derived values for each material
  const fullMaterials: LineItem[] = rawMaterials.map(rawItem => ({
    ...rawItem,
    ...calculateLineItemValues(rawItem)
  }));

  // Calculate new totals
  const updatedTotals = calculateTotals(
    fullMaterials,
    quotes[quoteIndex].specifications.area
  );

  // Update the quote
  const updatedQuote: Quote = {
    ...quotes[quoteIndex],
    materials: fullMaterials,
    totals: updatedTotals,
    updatedAt: new Date().toISOString()
  };

  quotes[quoteIndex] = updatedQuote;
  
  // No need to save here as the useQuote hook will handle saving

  return { ...updatedQuote };
};

export const updateQuoteName = async (id: string, name: string): Promise<Quote> => {
  const quoteIndex = quotes.findIndex(q => q.id === id);
  if (quoteIndex === -1) {
    throw new Error(`Quote with ID ${id} not found`);
  }

  // Update the quote name
  const updatedQuote: Quote = {
    ...quotes[quoteIndex],
    name,
    updatedAt: new Date().toISOString()
  };

  quotes[quoteIndex] = updatedQuote;
  
  // No need to save here as the useQuote hook will handle saving

  return { ...updatedQuote };
};
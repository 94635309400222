import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '@/lib/axios';
import * as Sentry from '@sentry/react';

const BASE_URL = '/admin/device';

export type Device = {
  id: number;
  organizationId: number;
  organizationName: string;
  name: string;
  type: string;
  imei: string;
  status: string;
  notificationsEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const fetchDevice = async ({ deviceId, signal }: { deviceId: string; signal?: AbortSignal }) => {
  try {
    const response = await axiosClient.get<Device>(`${BASE_URL}/${deviceId}`, { signal });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const useDevice = (deviceId: string) => {
  return useQuery({
    queryKey: ['device', deviceId],
    queryFn: ({ signal }) => fetchDevice({ deviceId, signal }),
  });
}; 
import { BASE_MATERIALS, getBaseMaterial } from "../data/baseMaterials";
import { LineItem, Quote, RawLineItem, RawSpecification } from "../types";
import { QuoteSpecifications } from "../types";
import { calculateLineItemValues, calculateSpecificationValues, calculateTotals, calculateTotalWashboxes, calculateUnits } from "./materialCalculator";
import { getMaterialWithUserSettings } from "./materialSettingsService";

export function generateMaterialLineItem(
    baseMaterialId: string,
    specs: QuoteSpecifications
): LineItem | null {
    // Get the base material
    const baseMaterial = getBaseMaterial(baseMaterialId);
    if (!baseMaterial) return null;

    // Apply user settings to the base material
    const customizedMaterial = getMaterialWithUserSettings(baseMaterial);

    // Calculate the number of units based on specifications
    const units = calculateUnits(customizedMaterial, specs);

    // Create the raw line item
    const rawLineItem: RawLineItem = {
        id: crypto.randomUUID(), // Generate a unique ID
        type: customizedMaterial.type,
        name: customizedMaterial.name,
        units,
        unit: customizedMaterial.unit,
        costPerUnit: customizedMaterial.costPerUnit,
        markup: customizedMaterial.markup,
        comment: customizedMaterial.description
    };

    // Calculate derived values
    const calculatedValues = calculateLineItemValues(rawLineItem);

    // Return the complete line item
    return {
        ...rawLineItem,
        ...calculatedValues
    };
}

export function generateMaterialLineItems(
    baseMaterialIds: string[],
    specs: QuoteSpecifications
): LineItem[] {
    const lineItems: LineItem[] = [];

    for (const id of baseMaterialIds) {
        const lineItem = generateMaterialLineItem(id, specs);
        if (lineItem) {
            lineItems.push(lineItem);
        }
    }

    return lineItems;
}


export const generateSampleQuote = () => {  
    // Create raw specifications
    const rawSpecs: RawSpecification = {
      width: 30,
      length: 60,
      gravelDepth: 100,
      sandDepth: 150,
      sandTopLayer: 0,
      washboxSpacing: 13,
      areaPerBasin: 2000,
      hasWashboxes: true,
      hasDriplines: true,
      hasGeothermal: false
    };
  
    // Calculate derived values
    const calculatedSpecs = calculateSpecificationValues(rawSpecs);
  
    // Complete specifications
    const fullSpecs: QuoteSpecifications = {
      ...rawSpecs,
      ...calculatedSpecs
    };
  
    // Generate materials using our new system
    const fullMaterials: LineItem[] = BASE_MATERIALS.map(material =>
      generateMaterialLineItem(material.type, fullSpecs)
    ).filter((item) => item !== null);
  
  
    // Calculate totals
    const totals = calculateTotals(fullMaterials, fullSpecs.area);
  
    // Create sample quote
    const sampleQuote: Quote = {
      id: crypto.randomUUID(),
      name: 'Sample Capillary Flow Project',
      specifications: fullSpecs,
      materials: fullMaterials,
      totals,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: 'draft'
    };
  
    return sampleQuote;
  };
  